import { Observable } from "rxjs";
import { AbstractScreenHandler, CRUDGenericService, TypeValidatorService } from 'attcei-bo-fw';
import { BasicCollectionMessageDTO } from '../classes/dto/BasicCollectionMessageDTO';
import { CollectionMessageDTO } from '../classes/dto/CollectionMessageDTO';
import { ScreenHandler } from 'attcei-bo-fw/lib/screen/interfaces/ScreenHandler';
import { Injectable } from '@angular/core';
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { Sort } from '@angular/material/sort';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';

@Injectable({
    providedIn:'root'
})
export class CollectionMessageScreenHandler extends AbstractScreenHandler<BasicCollectionMessageDTO, CollectionMessageDTO> implements ScreenHandler<CollectionMessageDTO, CollectionMessageDTO>{
    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }
    
    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<CollectionMessageDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }

    post(item: BasicCollectionMessageDTO):Observable<CollectionMessageDTO> {
        console.log("Recebi no post -> ", item);
        var dto: BasicCollectionMessageDTO = super.parse(item);
        var res: Observable<CollectionMessageDTO> = this.service.postData(this.getURL(),dto,true);
        console.log("result -> ", res);
        return res;
    }

    update(item: CollectionMessageDTO):Observable<CollectionMessageDTO> {
        console.log("Recebi no update -> ", item);
        var dto: CollectionMessageDTO = super.parse(item);
        var res: Observable<CollectionMessageDTO> = this.service.putData(this.getURL(), dto.id, dto, true);
        console.log("parsed -> ", res);
        return res;
    }

    delete(item: any):Observable<CollectionMessageDTO> {
        return this.service.delete(this.getURL(), item.id, true);
    }

    getItem(id: number):Observable<CollectionMessageDTO> {
        return this.service.getDataItem(this.getURL(), true, id);
    }

    getURL(): string {
        return "collectionmessage"
    }

    getId(item: CollectionMessageDTO): number {
        return item.id;
    }
    
    protected getUpdateTypeName(): string {
        return "CollectionMessageDTO";
   
    }    
    
    protected getCreateTypeName(): string {
        return "BasicCollectionMessageDTO";
    }
}