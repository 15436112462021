export class BaseScheduleDTO {
    constructor(
        public id: number,
        public routeTemplateId: number,
        public vehicleId: number,
        public code: string,
        public date: Date,
        public locations: number[]
    ) {}
}
