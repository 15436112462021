import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {

  public event: any;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    this.event = data.event;
  }

  ngOnInit() {
  }

}
