import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationScreenHandler } from 'src/app/handlers/LocationScreenHandler';
import screen from '../../../../assets/LocationScreen.json'
import historyScreen from '../../../../assets/LocationHistoryScreen.json'
import { LocationHistoryScreenHandler } from 'src/app/handlers/LocationHistoryScreenHandler';


@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss']
})
export class LocationDetailComponent implements OnInit {

  public eventFilter: any;

  public screenStructure = JSON.parse(JSON.stringify(screen))

  public historyScreenStructure = JSON.parse(JSON.stringify(historyScreen))

  @Input() uid: string;

  public filter: any = {};

  constructor(private route:ActivatedRoute, 
    public screenHandler: LocationScreenHandler, 
    public historyScreenHandler: LocationHistoryScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');

    this.eventFilter = {location: Number.parseInt(this.uid)};

    this.filter = {locationId: Number.parseInt(this.uid)};
  }
}
