import { DisplayRouteDTO } from '../tabular-builder/dto/DisplayRouteDTO';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';

export class GiorDataSource<T> implements DataSource<T> {

    private subject = new BehaviorSubject<T[]>([]);

    getSubject(): Subject<T[]> {
        return this.subject;
    }

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        //this.subject.complete();
    }

    isEmpty(): Observable<boolean> {
        return this.subject.pipe(map(d => d.length === 0));
    }
}
