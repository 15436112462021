import { Component, OnInit } from '@angular/core';
import { CodeGeneratorScreenHandler } from 'src/app/handlers/CodeGeneratorScreenHandler';
import screen from '../../../../assets/CodeGeneratorScreen.json';


@Component({
  selector: 'app-codegenerator-list',
  templateUrl: './codegenerator-list.component.html',
  styleUrls: ['./codegenerator-list.component.scss']
})
export class CodeGeneratorListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(public screenHandler: CodeGeneratorScreenHandler) { }

  ngOnInit() {
  }

}