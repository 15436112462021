import { LocationDTO } from './dto/LocationDTO';
import { RouteDTO } from './dto/RouteDTO';
import { TableLocationWaypoint } from './TableLocationWaypoint';
import { WaypointStatus } from './dto/WaypointStatus';
import { RouteStatus } from './dto/RouteStatus';

export class RouteUtils{

    static orderData(route:RouteDTO, locations: LocationDTO[]): Array<TableLocationWaypoint>{
        var data = new Array<TableLocationWaypoint>();
        //route.status = RouteStatus[route.status];
        
        //ordering locations, for correctness sake
        var orderedLocations = locations.sort((a,b) => a.id - b.id);
    
        //orderning waypoints
        var orderedWaypoints = route.waypoints.sort((a,b) => a.locationId - b.locationId);
        
        //matching both together
        for(var i = 0; i < orderedLocations.length; i++){
         // orderedWaypoints[i].status = WaypointStatus[orderedWaypoints[i].status];
          data.push(new TableLocationWaypoint(orderedLocations[i], orderedWaypoints[i]));
        }

        return data;
      }
}