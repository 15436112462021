import { Observable } from "rxjs";
import { AbstractScreenHandler, CRUDGenericService, TypeValidatorService } from 'attcei-bo-fw';
import { BasicLocationDTO } from '../classes/dto/BasicLocationDTO';
import { LocationDTO } from '../classes/dto/LocationDTO';
import { ScreenHandler } from 'attcei-bo-fw/lib/screen/interfaces/ScreenHandler';
import { Injectable } from '@angular/core';
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { Sort } from '@angular/material/sort';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';

@Injectable({
    providedIn:'root'
})

export class LocationScreenHandler extends AbstractScreenHandler<BasicLocationDTO, LocationDTO> implements ScreenHandler<BasicLocationDTO, LocationDTO>{
    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }
    
    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<LocationDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }

    post(item: BasicLocationDTO):Observable<LocationDTO> {
        console.log("Recebi no post -> ", item);
        var dto: BasicLocationDTO = super.parse(item);
        var res: Observable<LocationDTO> = this.service.postData(this.getURL(),dto,true);
        console.log("result -> ", res);
        return res;
    }

    update(item: LocationDTO):Observable<LocationDTO> {
        console.log("Recebi no update -> ", item);
        var dto: LocationDTO = super.parse(item);
        var res: Observable<LocationDTO> = this.service.putData(this.getURL(), dto.id, dto, true);
        console.log("parsed -> ", res);
        return res;
    }

    delete(item: LocationDTO):Observable<LocationDTO> {
        return this.service.delete(this.getURL(), item.id, true);
    }

    getItem(id: number):Observable<LocationDTO> {
        return this.service.getDataItem(this.getURL(), true, id);
    }

    getURL(): string {
        return "location"
    }

    getId(item: LocationDTO): number {
        return item.id;
    }
    
    protected getUpdateTypeName(): string {
        return "LocationDTO";
   
    }    
    
    protected getCreateTypeName(): string {
        return "BasicLocationDTO";
    }
}