import { Observable } from 'rxjs';
import { AbstractScreenHandler, CRUDGenericService, TypeValidatorService } from 'attcei-bo-fw';
import { ScreenHandler } from 'attcei-bo-fw/lib/screen/interfaces/ScreenHandler';
import { Injectable } from '@angular/core';
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { Sort } from '@angular/material/sort';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';
import { BasicCodeGeneratorDTO } from '../classes/dto/BasicCodeGeneratorDTO';
import { CodeGeneratorDTO } from '../classes/dto/CodeGeneratorDTO';

@Injectable({
    providedIn:'root'
})
export class CodeGeneratorScreenHandler extends AbstractScreenHandler<BasicCodeGeneratorDTO, CodeGeneratorDTO> implements ScreenHandler<BasicCodeGeneratorDTO, CodeGeneratorDTO>{
    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }
    
    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<CodeGeneratorDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }

    post(item: BasicCodeGeneratorDTO): Observable<CodeGeneratorDTO> {
        console.log('Recebi no post -> ', item);
        var dto: BasicCodeGeneratorDTO = super.parse(item);
        var res: Observable<CodeGeneratorDTO> = this.service.postData(this.getURL(),dto,true);
        console.log('result -> ', res);
        return res;
    }

    update(item: CodeGeneratorDTO):Observable<CodeGeneratorDTO> {
        console.log('Recebi no update -> ', item);
        var dto: CodeGeneratorDTO = super.parse(item);
        var res: Observable<CodeGeneratorDTO> = this.service.putData(this.getURL(), dto.id, dto, true);
        console.log('parsed -> ', res);
        return res;
    }

    delete(item: any):Observable<CodeGeneratorDTO> {
        return this.service.delete(this.getURL(), item.id, true);
    }

    getItem(id: number):Observable<CodeGeneratorDTO> {
        return this.service.getDataItem(this.getURL(), true, id);
    }

    getURL(): string {
        return 'codegenerator';
    }

    getId(item: CodeGeneratorDTO): number {
        return item.id;
    }

    protected getUpdateTypeName(): string {
        return 'CodeGeneratorDTO';
    }

    protected getCreateTypeName(): string {
        return 'BasicCodeGeneratorDTO';
    }
}