import { Observable } from "rxjs";
import { AbstractScreenHandler, CRUDGenericService, TypeValidatorService } from 'attcei-bo-fw';
import { BasicTrailerDTO } from '../classes/dto/BasicTrailerDTO';
import { TrailerDTO } from '../classes/dto/TrailerDTO';
import { ScreenHandler } from 'attcei-bo-fw/lib/screen/interfaces/ScreenHandler';
import { Injectable } from '@angular/core';
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';
import { Sort } from '@angular/material/sort';

@Injectable({
    providedIn:'root'
})

export class TrailerScreenHandler extends AbstractScreenHandler<BasicTrailerDTO, TrailerDTO> implements ScreenHandler<BasicTrailerDTO, TrailerDTO>{
    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }
    
    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<TrailerDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }

    post(item: BasicTrailerDTO):Observable<TrailerDTO> {
        console.log("Recebi no post -> ", item);
        var dto: BasicTrailerDTO = super.parse(item);
        var res: Observable<TrailerDTO> = this.service.postData(this.getURL(),dto,true);
        console.log("result -> ", res);
        return res;
    }

    update(item: TrailerDTO):Observable<TrailerDTO> {
        console.log("Recebi no update -> ", item);
        var dto: TrailerDTO = super.parse(item);
        var res: Observable<TrailerDTO> = this.service.putData(this.getURL(), dto.id, dto, true);
        console.log("parsed -> ", res);
        return res;
    }

    delete(item: any):Observable<TrailerDTO> {
        return this.service.delete(this.getURL(), item.id, true);
    }

    getItem(id: number):Observable<TrailerDTO> {
        return this.service.getDataItem(this.getURL(), true, id);
    }

    getURL(): string {
        return "trailer"
    }

    getId(item: TrailerDTO): number {
        return item.id;
    }
    
    protected getUpdateTypeName(): string {
        return "TrailerDTO";
   
    }    
    
    protected getCreateTypeName(): string {
        return "BasicTrailerDTO";
    }
}