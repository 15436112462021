import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CRUDGenericService } from 'attcei-bo-fw';
import { Router } from '@angular/router';

function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}

@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.scss']
})
export class UsersCreateComponent implements OnInit {

  public userForm: FormGroup;

  public submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: CRUDGenericService,
    private router: Router) {

      this.userForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$')]],
        role: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
      }, {
          validator: MustMatch('password', 'confirmPassword')
      });
     }

  ngOnInit() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.userForm.controls; }

  public onSubmit(user:any) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.userForm.invalid) {
        return;
    }

    this.service.postData('user', {
      name: user.name,
      email: user.email,
      role: user.role,
      password: user.password
    }, true).subscribe(u => {
      if (u.id) {
        this.router.navigate(['/users/list']);
      }
    });
  }
}
