import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrailerScreenHandler } from 'src/app/handlers/TrailerScreenHandler';
import screen from '../../../../assets/TrailerScreen.json'

@Component({
  selector: 'app-trailer-detail',
  templateUrl: './trailer-detail.component.html',
  styleUrls: ['./trailer-detail.component.scss']
})
export class TrailerDetailComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen))
  @Input() uid: string;

  constructor(private route:ActivatedRoute, public screenHandler: TrailerScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');
  }

}
