import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IncidentScreenHandler } from 'attcei-bo-fw';
import screen from '../../../../assets/IncidentScreen.json'

@Component({
  selector: 'app-incident-detail',
  templateUrl: './incident-detail.component.html',
  styleUrls: ['./incident-detail.component.scss']
})
export class IncidentDetailComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen))
  @Input() uid: string;

  constructor(private route:ActivatedRoute, public screenHandler: IncidentScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');
  }

}
