import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VehicleScreenHandler } from '../../../handlers/VehicleScreenHandler'
import screen from '../../../../assets/VehicleScreen.json'

@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.scss']
})
export class VehicleDetailComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen))
  @Input() uid: string;

  constructor(private route:ActivatedRoute, public screenHandler: VehicleScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');
  }

}
