import { ScheduleDTO } from './ScheduleDTO';
import { TypedRouteLocationDTO } from './TypedRouteLocationDTO';

export class ScheduledLocationDTO {
    constructor(
        public source: TypedRouteLocationDTO,
        public schedule: ScheduleDTO
    ) {}

    getTime() {
        return this.schedule.date.getTime();
    }
}
