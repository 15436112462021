import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IncidentScreenHandler } from 'attcei-bo-fw';
import { ContainerScreenHandler } from 'src/app/handlers/ContainerScreenHandler';
import { MeasurementScreenHandler } from 'src/app/handlers/MeasurementScreenHandler';
import measurementScreen from '../../../../assets/CollectionRecordScreen.json';
import incidentScreen from '../../../../assets/UserIncidentScreen.json';
import screen from '../../../../assets/ContainerScreen.json'
import { CollectionRecordScreenHandler } from 'src/app/handlers/CollectionRecordScreenHandler';

@Component({
  selector: 'app-container-detail',
  templateUrl: './container-detail.component.html',
  styleUrls: ['./container-detail.component.scss']
})
export class ContainerDetailComponent implements OnInit {

  public measurementFilter: any;

  public incidentFilter: any;

  public measurementScreenStructure = JSON.parse(JSON.stringify(measurementScreen))

  public incidentScreenStructure = JSON.parse(JSON.stringify(incidentScreen))

  public screenStructure = JSON.parse(JSON.stringify(screen))

  @Input() uid: string;

  constructor(private route:ActivatedRoute, 
    public screenHandler: ContainerScreenHandler,
    public measurementScreenHandler: CollectionRecordScreenHandler,
    public incidentScreenHandler: IncidentScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');

    const id = Number.parseInt(this.uid);

    this.incidentFilter = {containerId: id};

    this.measurementFilter = {containerId: id};
  }

}
