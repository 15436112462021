import '../polyfills';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AttceiBoFwModule } from 'attcei-bo-fw';
import { ChartsModule } from 'ng2-charts';
import { SchemaValidatorLoaderComponent } from './schema-validator-loader/schema-validator-loader.component';
import { VehicleListComponent } from './navigation-components/vehicle/vehicle-list/vehicle-list.component';
import { VehicleDetailComponent } from './navigation-components/vehicle/vehicle-detail/vehicle-detail.component';
import { TrailerListComponent } from './navigation-components/trailer/trailer-list/trailer-list.component';
import { TrailerDetailComponent } from './navigation-components/trailer/trailer-detail/trailer-detail.component';
import { LocationDetailComponent } from './navigation-components/location/location-detail/location-detail.component';
import { LocationListComponent } from './navigation-components/location/location-list/location-list.component';
import { ContainerListComponent } from './navigation-components/container/container-list/container-list.component';
import { ContainerDetailComponent } from './navigation-components/container/container-detail/container-detail.component';
import { RouteListComponent } from './navigation-components/route/route-list/route-list.component';
import { RouteBuilderComponent } from './navigation-components/route/route-builder/route-builder.component';
import { MatMenuModule } from '@angular/material/menu';
import { GiorListComponent } from './navigation-components/users/users-list/users-list.component';
import { UserDetailComponent } from './navigation-components/users/users-detail/users-detail.component';
import { TestDevComponent } from './test-dev/test-dev.component';
import { TabularBuilderComponent } from './navigation-components/route/route-builder/tabular-builder/tabular-builder.component';
import { RouteDetailsComponent } from './navigation-components/route/route-builder/route-details/route-details.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import { MatNativeDateModule } from "@angular/material/core";
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator'
import { IncidentDetailComponent } from './navigation-components/incident/incident-detail/incident-detail.component';
import { IncidentListComponent } from './navigation-components/incident/incident-list/incident-list.component';
import { RouteCompletedDetailComponent } from './navigation-components/route/route-completed-detail/route-completed-detail.component';
import { RouteCompletedTableComponent } from './navigation-components/route/route-completed-table/route-completed-table.component';
import { RouteCompletedMapComponent } from './navigation-components/route/route-completed-map/route-completed-map.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { MapBuilderComponent } from './navigation-components/route/route-builder/map-builder/map-builder.component';
import { AgmCoreModule } from '@agm/core';
import { BuilderDialogComponent } from './navigation-components/route/route-builder/builder-dialog/builder-dialog.component';
import { ScheduleEditorDialogComponent } from './navigation-components/route/route-builder/schedule-editor-dialog/schedule-editor-dialog.component';
import { WelcomeComponent } from './navigation-components/welcome/welcome.component';
import { RoutestatsComponent } from './navigation-components/statistics/routestats/routestats.component';
import { StatisticsReportsComponent } from './navigation-components/statistics/reports/reports.component';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { UsersCreateComponent } from './navigation-components/users/users-create/users-create.component';
import { getPortuguesePaginatorIntl } from './portuguese-paginator-intl';
import { EventDetailsComponent } from './navigation-components/event-details/event-details.component';
import { EventsComponent } from './navigation-components/events/events.component';
import { ContainerTypeDetailComponent } from './navigation-components/container-type/container-type-detail/container-type-detail.component';
import { ContainerTypeListComponent } from './navigation-components/container-type/container-type-list/container-type-list.component';
import { MessageListComponent } from './navigation-components/message/message-list/message-list.component';
import { MessageDetailComponent } from './navigation-components/message/message-detail/message-detail.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ServiceWorkerModule } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt-PT';
import { RouteTemplateListComponent } from './navigation-components/route-template/route-template-list/route-template-list.component';
import { RouteTemplateDetailComponent } from './navigation-components/route-template/route-template-detail/route-template-detail.component';
import { RouteCompletedStatsComponent } from './navigation-components/route/route-completed-stats/route-completed-stats.component';
import { LocationMapComponent } from './navigation-components/location/location-map/location-map.component';
import { RouteCompletedEditorComponent } from './navigation-components/route/route-completed-editor/route-completed-editor.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { LocalityListComponent } from './navigation-components/locality/locality-list/locality-list.component';
import { LocalityDetailComponent } from './navigation-components/locality/locality-detail/locality-detail.component';
import { environment } from 'src/environments/environment';
import { RouteCompletedLocationsComponent } from './navigation-components/route/route-completed-locations/route-completed-locations.component';
import { UserPasswordComponent } from './navigation-components/users/user-password/user-password.component';
import { RouteTemplateEditComponent } from './navigation-components/route-template/route-template-edit/route-template-edit.component';
import { RouteTemplateScheduleComponent } from './navigation-components/route-template/route-template-schedule/route-template-schedule.component';
import { RouteTemplateEditMapComponent } from './navigation-components/route-template/route-template-edit-map/route-template-edit-map.component';
import { RouteTemplateEditTableComponent } from './navigation-components/route-template/route-template-edit-table/route-template-edit-table.component';
import { RouteTemplateEditTableDestComponent } from './navigation-components/route-template/route-template-edit-table-dest/route-template-edit-table-dest.component';
import { CodeGeneratorListComponent } from './navigation-components/codegenerator/codegenerator-list/codegenerator-list.component';
import { CodeGeneratorDetailComponent } from './navigation-components/codegenerator/codegenerator-detail/codegenerator-detail.component';
import { ContainerUploadComponent } from './navigation-components/container/container-upload/container-upload.component';
import { ParishDetailComponent } from './navigation-components/parish/parish-detail/parish-detail.component';
import { ParishListComponent } from './navigation-components/parish/parish-list/parish-list.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { RouteTemplateEditRouteComponent } from './navigation-components/route-template/route-template-edit-route/route-template-edit-route.component';
import { NewsDetailComponent } from './navigation-components/news/news-detail/news-detail.component';
import { NewsListComponent } from './navigation-components/news/news-list/news-list.component';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';


registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    SchemaValidatorLoaderComponent,
    VehicleListComponent,
    VehicleDetailComponent,
    TrailerListComponent,
    TrailerDetailComponent,
    LocationDetailComponent,
    LocationListComponent,
    ContainerListComponent,
    ContainerDetailComponent,
    RouteBuilderComponent,
    RouteListComponent,
    GiorListComponent,
    UserDetailComponent,
    TestDevComponent,
    TabularBuilderComponent,
    RouteDetailsComponent,
    IncidentDetailComponent,
    IncidentListComponent,
    RouteCompletedDetailComponent,
    RouteCompletedTableComponent,
    RouteCompletedMapComponent,
    MapBuilderComponent,
    AppComponent,
    BuilderDialogComponent,
    ScheduleEditorDialogComponent,
    WelcomeComponent,
    RoutestatsComponent,
    UsersCreateComponent,
    EventDetailsComponent,
    EventsComponent,
    ContainerTypeDetailComponent,
    ContainerTypeListComponent,
    MessageListComponent,
    MessageDetailComponent,
    RouteTemplateDetailComponent,
    RouteTemplateListComponent,
    RouteCompletedStatsComponent,
    LocationMapComponent,
    RouteCompletedEditorComponent,
    LocalityListComponent,
    LocalityDetailComponent,
    ParishListComponent,
    ParishDetailComponent,
    ContainerUploadComponent,
    CodeGeneratorListComponent,
    CodeGeneratorDetailComponent,
    RouteCompletedLocationsComponent,
    UserPasswordComponent,
    RouteTemplateEditComponent,
    RouteTemplateScheduleComponent,
    RouteTemplateEditMapComponent,
    RouteTemplateEditTableComponent,
    RouteTemplateEditTableDestComponent,
    RouteTemplateEditRouteComponent,
    StatisticsReportsComponent,
    NewsListComponent,
    NewsDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatInputModule,
    MatTabsModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    ChartsModule,
    DragDropModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatSelectModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatMenuModule,
    FormsModule,
    MatSidenavModule,
    CdkTableModule,
    CdkTreeModule,
    ColorPickerModule,
    NgxMaterialTimepickerModule.setLocale('pt-PT'),
    AttceiBoFwModule.forRoot(environment),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDy3sDCC6eJzAxQ_69E6FqBMvLwqQ-gT0A'
    }),
    GooglePlaceModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  entryComponents: [
    RouteDetailsComponent,
    BuilderDialogComponent,
    EventDetailsComponent,
    ScheduleEditorDialogComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl() },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
  bootstrap: [AppComponent, AppComponent]
})
export class AppModule { }
