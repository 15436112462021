import { Component, OnInit, Input } from '@angular/core';
import { NewsScreenHandler } from 'src/app/handlers/NewsScreenHandler';
import screen from '../../../../assets/NewsScreen.json';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  @Input() uid: string;

  constructor(private route: ActivatedRoute, public screenHandler: NewsScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');
  }


}
