import { TypedRouteLocationDTO } from '../../dto/TypedRouteLocationDTO';
import { ScheduledLocationDTO } from '../../dto/ScheduledLocationDTO';
import { BehaviorSubject } from 'rxjs';

export class RouteDetailsDTO {
    constructor(
        public id: number,
        public code: string,
        public date: Date,
        public locations: TypedRouteLocationDTO[],
        public scheduled: BehaviorSubject<Map<string, ScheduledLocationDTO>>
    ) {}
}
