import { TypedRouteLocationDTO } from '../../dto/TypedRouteLocationDTO';

export class DisplayLocationDetailDTO {
    constructor(
        public source: TypedRouteLocationDTO,
        public id: number,
        public code: string,
        public address: string,
        public installedCapacity: number,
        public nContainers: number,
        public lastCollection: Date,
        public lastMeasurement: Date,
        public lastValue: number,
        public predictionT0: number,
        public predictionT1: number,
        public predictionT2: number
    ) {}
}
