import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { LocationDTO } from '../route-template-edit/LocationDTO';

@Component({
  selector: 'app-route-template-edit-table-dest',
  templateUrl: './route-template-edit-table-dest.component.html',
  styleUrls: ['./route-template-edit-table-dest.component.scss']
})
export class RouteTemplateEditTableDestComponent implements OnInit  {

  @Input() locations = new Observable<LocationDTO[]>();

  @Input() action: string;

  @Output() locationAction: EventEmitter<LocationDTO> = new EventEmitter<LocationDTO>();

  displayedColumns: string[] = ['code', 'address', 'locality', 'action'];

  dataSource: MatTableDataSource<LocationDTO> = new MatTableDataSource();;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() {
    // Assign the data to the data source for the table to render
  }


  ngOnInit() {
    this.dataSource
    this.locations.subscribe(s => {
      this.dataSource.data = s;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
