import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CRUDGenericService } from 'attcei-bo-fw';
import { Observable } from 'rxjs';
import { BuilderDialogComponent } from '../../route/route-builder/builder-dialog/builder-dialog.component';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss']
})

export class UserPasswordComponent implements OnInit {

  public passwordError = new PasswordErrorStateMatcher();

  public password: FormGroup;

  private id: number;
  
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private service: CRUDGenericService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,) { }

  ngOnInit() {
    this.id = Number.parseInt(this.route.snapshot.paramMap.get('id'));

    this.password = this.fb.group({
      password: ['', [
        Validators.required, 
        Validators.minLength(5),
        Validators.maxLength(255),
        Validators.pattern(/^\S*$/)]],
      confirmPassword: ['']
    }, {validator: this.checkPasswords });
  }

  changePassword() {
    this.openGenericDialog(
      'Alteração de password',
      'Tem a certeza que pretende alterar permanentemente a password ao utilizador?',
      'Cancelar', 'Alterar')
      .subscribe(result => {
        if (result === 'Alterar') {
          this.service.putData('user/changepw', this.id, {newPassword: this.password.value.password}, true).subscribe(r => {
            this.snackBar.open('Alteração efectuada', '', { duration: 2000 });
          }, error => {
            this.snackBar.open('Ocorreu um erro a alterar a password, por favor tente mais tarde', '', { duration: 2000 });
          });
        }
      });
    console.log(this.id, this.password.value.password);
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSame: true }     
  }
  
  resetPassword() {
    this.openGenericDialog(
      'Envio de Reset de Password',
      'Deseja enviar uma nova password para o utilizador?',
      'Cancelar', 'Enviar')
      .subscribe(result => {
        if (result === 'Enviar') {
          const snack = this.snackBar.open('A enviar....', '', { duration: 2000 });
          this.service.putData('user/resetpw', this.id, {}, true).subscribe(r => {
            snack.dismiss();
            this.snackBar.open('E-Mail de reset de password enviado', '', { duration: 2000 });
          }, error => {
            snack.dismiss();
            this.snackBar.open('Ocorreu um erro a enviar e-mail, por favor tente mais tarde', '', { duration: 2000 });
          });
        }
      });
  }

  openGenericDialog(title: string, message: string, ...btns: string[]): Observable<any> {
    const config = new MatDialogConfig<any>();
    config.data = {title, message, btns};
    const dialogRef = this.dialog.open(BuilderDialogComponent, config);
    return dialogRef.afterClosed();
  }
}

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}