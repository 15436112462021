import { TypedRouteLocationDTO } from '../../dto/TypedRouteLocationDTO';
import {} from 'googlemaps';

export class MapLocationDTO {
    constructor(
        public source: TypedRouteLocationDTO,
        public uniquecode: string,
        public code: string,
        public color: string,
        public latitude: number,
        public longitude: number,
        public icon: string,
        public map: google.maps.Map,
        public prediction: number
    ) {}
}
