import { Component, OnInit, Input } from '@angular/core';
import { RouteDTO } from 'src/app/classes/dto/RouteDTO';
import { LocationDTO } from 'src/app/classes/dto/LocationDTO';
import { ChartDataSets } from 'chart.js';
import { Label, SingleLineLabel } from 'ng2-charts';
import { ExcelService } from 'attcei-bo-fw';

@Component({
  selector: 'app-route-completed-stats',
  templateUrl: './route-completed-stats.component.html',
  styleUrls: ['./route-completed-stats.component.scss']
})
export class RouteCompletedStatsComponent implements OnInit {

  @Input() route: RouteDTO;
  
  @Input() locations: LocationDTO[];
  public chartOptions = {
    legend : {display: false},
    scales : {
        yAxes : [{
            ticks : {
                beginAtZero : true
            }
        }],
        xAxes: [{
          ticks: {
            precision: 0
          }
        }]
    }
  };

  public fillingData: ChartDataSets[] = [];

  public fillingLabels: SingleLineLabel[] = ['0%', '25%', '50%', '75%', '100%', '110%'];

  public contaminationData: ChartDataSets[] = [];

  public contaminationLabels: SingleLineLabel[] = ['Alta', 'Média', 'Baixa', 'Nenhuma'];

  constructor(private excel: ExcelService) { }

  ngOnInit() {
  }

  ngOnChanges () {
    if (this.route && this.route.waypoints && this.route.waypoints.length > 0) {
      this.refreshFilling();
      this.refreshContamination();
    }
  }

  refreshFilling() {
    const data = {'0%': 0, '25%': 0, '50%': 0, '75%': 0, '100%': 0, '110%': 0};
    this.route.waypoints.forEach(l => {
      if (l.measuredFilling == 0) data['0%']++;
      else if (l.measuredFilling == 0.25) data['25%']++;
      else if (l.measuredFilling == 0.50) data['50%']++;
      else if (l.measuredFilling == 0.75) data['75%']++;
      else if (l.measuredFilling == 1.00) data['100%']++;
      else if (l.measuredFilling == 1.10) data['110%']++;
    })
    this.fillingData = [{
        data: this.fillingLabels.map(fl => data[fl])
    }];
  }

  hasFilling() {
    return this.route 
      && this.route.waypoints 
      && this.route.waypoints.length > 0 
      && this.route.waypoints.filter(l => l.measuredFilling != null).length > 0;
  }

  calcAvgFilling(): number {
    if (this.route.waypoints.length > 0) {
      const nPoints = this.route.waypoints
        .filter(el => el.measuredFilling != null)
        .length;

      if (nPoints > 0) {
        return this.route.waypoints
          .filter(el => el.measuredFilling != null)
          .reduce((accumulator, mf) => accumulator + mf.measuredFilling, 0) / nPoints;
      }
    }
    return null;
  }

  calcSumPoints(): number {
    return this.route.waypoints
      .filter(wp => wp.collectedAt != null)
      .reduce((accumulator, mf) => accumulator + 1, 0);
  }

  calcDuration(): number {
    const start = this.toDate(this.route.startedAt).getTime();
    const end = this.toDate(this.route.endedAt).getTime();
    return (end - start) /  1000;
  }

  getDuration(): string {
    const dur = this.calcDuration();
    return [Math.floor(dur / 3600), ' horas e ', Math.round((dur - Math.floor(dur / 3600) * 3600) / 60), ' minutos'].join('');
  }

  toDate(obj: any): Date {
    if (obj instanceof Array) {
      return this.toDate({date:{
            year:obj[0],month:obj[1] - 1,day:obj[2]},
            time:{hour:obj[3],minute:obj[4],second:0, nano:0}});
    } 
    const dt = new Date();
    dt.setFullYear(obj.date.year);
    dt.setMonth(obj.date.month);
    dt.setDate(obj.date.day);
    dt.setHours(obj.time.hour);
    dt.setMinutes(obj.time.minute);
    dt.setSeconds(obj.time.second);
    return dt;
  }


  refreshContamination() {
    const data = {'Alta': 0,'Média': 0,'Baixa': 0,'Nenhuma': 0};
    this.route.waypoints.forEach(l => {
      if (l.measuredContamination == 'HIGH') data['Alta']++;
      else if (l.measuredContamination == 'MEDIUM') data['Média']++;
      else if (l.measuredContamination == 'LOW') data['Baixa']++;
      else if (l.measuredContamination == 'NONE') data['Nenhuma']++;
    })
    this.contaminationData = [{
        data: this.contaminationLabels.map(fl => data[fl])
    }];
  }

  hasContamination() {
    return this.route 
      && this.route.waypoints 
      && this.route.waypoints.length > 0 
      && this.route.waypoints.filter(l => !!l.measuredContamination).length > 0;
  }

  downloadXlsx(): void {
    const data = [
      ['Número de Pontos', this.route.waypoints.length],
      ['Número de Pontos Recolhidos', this.calcSumPoints()],
      ['Quilómetros Percorridos', this.route.endingKilometers - this.route.startingKilometers],
      ['Peso Recolhido', this.route.collectedWeight],
      ['Duração', this.getDuration()],
      ['Enchimento Médio', this.calcAvgFilling() * 100]
    ];

    data.push(['', '']);
    data.push(['Enchimento', '']);

    this.fillingLabels.forEach((v, i) => {
      data.push([v, this.fillingData[0].data[i].toString()]);
    });

    data.push(['', '']);
    data.push(['Contaminação', '']);

    this.contaminationLabels.forEach((v, i) => {
      data.push([v, this.contaminationData[0].data[i].toString()]);
    });

    this.excel.exportArrayAsExcelFile(data, 'estatisticas.xlsx');
  }

}
