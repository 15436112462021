import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LocationsService } from './locations.service';
import { Subject, combineLatest, BehaviorSubject, Observable, merge, of } from 'rxjs';
import { map, toArray, flatMap, switchMap, filter, tap, debounceTime, finalize } from 'rxjs/operators';
import { RouteLocationDTO } from './dto/RouteLocationDTO';
import { TypedRouteLocationDTO } from './dto/TypedRouteLocationDTO';
import { ScheduleDTO } from './dto/ScheduleDTO';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormControl, FormGroup } from '@angular/forms';
import { PredictionService } from './prediction.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BuilderDialogComponent } from './builder-dialog/builder-dialog.component';
import { ScheduledLocationDTO } from './dto/ScheduledLocationDTO';
import { VehiclesService } from './vehicles.service';
import { RouteVehicleDTO } from './dto/RouteVehicleDTO';
import { RoutesService } from './routes.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScheduleEditorDialogComponent } from './schedule-editor-dialog/schedule-editor-dialog.component';
import { ScheduledRouteLocationDTO } from './dto/ScheduledRouteLocationDTO';
import { BaseScheduleDTO } from './dto/BaseScheduleDTO';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-route-builder',
  templateUrl: './route-builder.component.html',
  styleUrls: ['./route-builder.component.scss'],
  providers: [LocationsService, PredictionService, VehiclesService, RoutesService]
})
export class RouteBuilderComponent implements OnInit {

  public vehicles: RouteVehicleDTO[];

  private material = new BehaviorSubject<string>('RUB');

  private colors = new BehaviorSubject<string>('#ffffff');

  public date = new BehaviorSubject<Date>(this.atMidDay(new Date()));

  public minFillingInput: FormControl;

  public minFilling = new BehaviorSubject<number>(75);

  public schedules = new Array<ScheduleDTO>();

  public scheduledLocations = new BehaviorSubject<Map<string, ScheduledLocationDTO>>(new Map<string, ScheduledLocationDTO>());

  private currentSchedule: ScheduleDTO;

  public commom = new BehaviorSubject<TypedRouteLocationDTO[]>([]);
  
  public mapSelected = new BehaviorSubject<boolean>(false);

  public dateInput: FormControl;

  public updates: Subject<Date> = new BehaviorSubject<Date>(new Date());

  public isLoading = false;

  public density = new BehaviorSubject<number>(0);

  private requestAsked = 0;

  private requestDone = 0;

  constructor(
    private locationService: LocationsService,
    private predictionService: PredictionService,
    private vehicleService: VehiclesService,
    private routeService: RoutesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.date.subscribe(d => console.log(d));

    this.dateInput = new FormControl();
    this.dateInput.valueChanges.pipe(map(d => {
      d.setHours(12, 0, 0, 0);
      return d;
    })).subscribe(this.date);
    this.dateInput.setValue(this.atMidDay(new Date()));

    this.minFillingInput = new FormControl();
    this.minFillingInput.valueChanges.subscribe(this.minFilling);

    this.colors.pipe(debounceTime(200))
      .subscribe(c => this.onScheduleChanged());

    const vehiclesObs = new BehaviorSubject<RouteVehicleDTO[]>([]);
    vehiclesObs.subscribe(v => this.vehicles = v);
    this.vehicleService.get().subscribe(vehiclesObs);

    // commom
    const allLocations = this.updates.pipe(
      tap(x => {
        this.isLoading = true;
        this.requestAsked++; 
      }),
      switchMap(d => merge(of([]), this.locationService.get().pipe(
        toArray(),
        finalize(() => {
          this.requestDone++;
          this.isLoading = this.requestAsked !== this.requestDone;
          console.log(this.requestDone, this.requestAsked);
        }))))
    );

    combineLatest([allLocations, this.material])
      .pipe(map(arr => this.filterByMaterial(arr[0], arr[1])))
      .subscribe(this.commom);

    const locationMap = this.commom
      .pipe(map(locations => {
        const m = new Map<number, TypedRouteLocationDTO>();
        locations.forEach(l => m.set(l.id, l));
        return m;
      }));

    const scheduleObs = new BehaviorSubject<BaseScheduleDTO[]>([]);
    this.routeService.getSchedule().subscribe(scheduleObs);
    combineLatest(scheduleObs, vehiclesObs, locationMap)
      .pipe(
        map(arr => arr[0].map(bs => {
          const s = new ScheduleDTO(
              bs.id,
              bs.routeTemplateId,
              bs.code,
              bs.date,
              false,
              bs.locations
                .map(id => arr[2].get(id))
                .filter(l => !!l)
                .map(l => new ScheduledRouteLocationDTO(l))
          );
          s.locations.forEach(l => l.prediction = this.predict(l.source, bs.date));
          s.vehicle = arr[1].filter(v => v.id === bs.vehicleId).shift();
          return s;
        })),
        map(schedules => schedules.filter(s => s.locations.length > 0))
      ).subscribe(s => {
        this.schedules = s;
        this.onScheduleChanged();
      });

    //replace material with corresponding density
    this.material
      .pipe(switchMap(m => this.routeService.getDensity(m)))
      .subscribe(this.density);

    this.updates.next(new Date());
  }

  onColorChange(color: string) {
    this.colors.next(color);
  }

  atMidDay(dt: Date): Date {
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 12, 0, 0, 0);
  }

  onDrop(schedule: ScheduleDTO, event: CdkDragDrop<TypedRouteLocationDTO[]>) {
    moveItemInArray(schedule.locations, event.previousIndex, event.currentIndex);
    this.onScheduleChanged();
  }

  onTabChange(evt: MatTabChangeEvent) {
    this.mapSelected.next(evt.index == 1);
  }

  removeLocation(schedule: ScheduleDTO, index: number) {
    schedule.locations.splice(index, 1);
    this.onScheduleChanged();
  }

  openDialog(title: string, message: string, ...btns: string[]): Observable<any> {
    const config = new MatDialogConfig<any>();
    config.data = {title, message, btns};
    const dialogRef = this.dialog.open(BuilderDialogComponent, config);
    return dialogRef.afterClosed();
  }



  onEditClick(schedule: ScheduleDTO) {
    const config = new MatDialogConfig<any>();
    config.data = {schedule};
    const dialogRef = this.dialog.open(ScheduleEditorDialogComponent, config);
    return dialogRef.afterClosed();
  }

  saveNewTemplate(schedule: ScheduleDTO) {
    if (!schedule.locations) {
      this.showErrorEmpty();
      return;
    }
    if (!schedule.code) {
      this.showErrorNoName();
      return;
    }
    this.routeService.saveTemplate(schedule).subscribe(r => {
      schedule.routeTemplateId = r.id;
      this.updates.next(new Date());
      this.snackBar.open('Nova Rota Guardada', '', { duration: 2000 });
    });
  }

  updateTemplate(schedule: ScheduleDTO) {
    if (!schedule.locations) {
      this.showErrorEmpty();
      return;
    }
    if (!schedule.code) {
      this.showErrorNoName();
      return;
    }
    this.routeService.updateTemplate(schedule).subscribe(r => {
      this.updates.next(new Date());
      this.snackBar.open('Rota Actualizada', '', { duration: 2000 });
    });
  }

  private showErrorNoName() {
    this.openDialog('Erro', 'Deve dar um nome à rota!', 'Ok');
  }

  saveNewSchedule(schedule: ScheduleDTO) {
    if (!schedule.locations) {
      this.showErrorEmpty();
      return;
    }
    if (!schedule.code) {
      this.showErrorNoName();
      return;
    }
    if (!schedule.vehicle) {
      this.showErrorNoVehicle();
      return;
    }
    this.routeService.saveNewSchedule(schedule).subscribe(r => {
      schedule.id = r.id;
      this.snackBar.open('Rota Agendada', '', { duration: 2000 });
    });
  }

  updateSchedule(schedule: ScheduleDTO) {
    if (!schedule.locations) {
      this.showErrorEmpty();
      return;
    }
    if (!schedule.code) {
      this.showErrorNoName();
      return;
    }
    if (!schedule.vehicle) {
      this.showErrorNoVehicle();
      return;
    }
    this.routeService.updateSchedule(schedule).subscribe(r => {
      this.snackBar.open('Agendamento Actualizado', '', { duration: 2000 });
    });
  }

  deleteSchedule(schedule: ScheduleDTO) {
    if (schedule.id) {
      this.routeService.deleteSchedule(schedule).subscribe(s => {
        this.snackBar.open('Agendamento Cancelado', '', { duration: 2000 });
        this.schedules.forEach( (item, index) => {
          if (item === schedule) {
            this.schedules.splice(index, 1);
          }
        });
        if (this.currentSchedule === schedule) {
          this.currentSchedule = null;
        }
        this.onScheduleChanged();
      });
    }
  }

  private showErrorNoVehicle() {
    this.openDialog('Erro', 'Deve associar um veículo à rota!', 'Ok');
  }

  private showErrorEmpty() {
    this.openDialog('Erro', 'A rota está vazia', 'Ok');
  }

  onScheduleChanged() {
    const locations = new Map<string, ScheduledLocationDTO>();
    this.schedules.forEach(schedule => {
      const time = schedule.date.getTime();
      schedule.locations.forEach(location => {
        const key = location.source.type + '_' + location.source.id;
        if (!locations.has(key) || locations.get(key).getTime() < time) {
          locations.set(key, new ScheduledLocationDTO(location.source, schedule));
        }
      });
    });
    this.scheduledLocations.next(locations);
  }

  onSchedule(scheduled: ScheduleDTO) {
    // is a route and there is no open schedule, ask
    if (!this.currentSchedule && scheduled.isRoute) {
      this.scheduleNewRoute(scheduled);
    } else if (scheduled.isRoute) {
      this.scheduleOrAddNewRoute(scheduled);
    } else if (!this.currentSchedule) {
      this.scheduleNewLocation(scheduled);
    } else if (!scheduled.shouldRemove) {
      this.addToSchedule(scheduled);
    } else {
      this.removeFromSchedule(scheduled);
    }
  }

  getTotalEstimatedCapacity(scheduled: ScheduleDTO): number {
    const capInLitters = scheduled.locations
      .map(l => l.prediction * l.source.totalCapacity)
      .reduce((a, b) =>  a + b, 0);

    return this.density.value * (capInLitters / 1000);
  }

  private addSchedule(schedule: ScheduleDTO) {
    schedule.locations.forEach(schlocation => {
      schlocation.prediction = this.predict(schlocation.source, schedule.date);
    });
    this.schedules.push(schedule);
    this.currentSchedule = schedule;
    this.onScheduleChanged();
  }

  private removeFromSchedule(schedule: ScheduleDTO) {
    const toRemove = schedule.locations.map(l => l.source.id);
    this.currentSchedule.locations = this.currentSchedule.locations
      .filter(l => !toRemove.includes(l.source.id));
    this.onScheduleChanged();
  }

  private addToSchedule(schedule: ScheduleDTO) {
    schedule.locations.forEach(schlocation => {
      schlocation.prediction = this.predict(schlocation.source, schedule.date);
    });
    this.currentSchedule.locations = this.mergeArrays(this.currentSchedule.locations, schedule.locations);
    this.onScheduleChanged();
  }

  private scheduleNewLocation(scheduled: ScheduleDTO) {
    this.openDialog(
      'Agendar Localização',
      'Deseja criar um novo agendamento?',
      'Cancelar', 'Ok')
      .subscribe(result => {
        if (result === 'Ok') {
          this.addSchedule(scheduled);
        }
      });
  }

  private scheduleOrAddNewRoute(scheduled: ScheduleDTO) {
    const openCode = this.currentSchedule.code ? this.currentSchedule.code : 'actual';
    this.openDialog(
      'Agendar Circuito',
      'Deseja adicionar os pontos da rota ' + scheduled.code + ' à rota ' + openCode + ' ou criar um novo agendamento?',
      'Cancelar', 'Adicionar', 'Agendar')
      .subscribe(result => {
        if (result === 'Agendar') {
          this.addSchedule(scheduled);
        }
        if (result === 'Adicionar') {
          this.addToSchedule(scheduled);
        }
      });
  }

  private mergeArrays<T>(a: T[], b: T[]): T[] {
    return a.concat(b).filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });
  }

  private scheduleNewRoute(scheduled: ScheduleDTO) {
    this.openDialog(
      'Agendar Circuito',
      'Deseja criar um novo agendamento utilizando a rota ' + scheduled.code + '?',
      'Cancelar', 'Agendar')
      .subscribe(result => {
        if (result === 'Agendar') {
          this.addSchedule(scheduled);
        }
      });
  }

  onOpenSchedule(schedule: ScheduleDTO) {
    this.currentSchedule = schedule;
  }

  onCloseSchedule(schedule: ScheduleDTO) {
    if (this.currentSchedule === schedule) {
      this.currentSchedule = null;
    }
  }

  predict(location: TypedRouteLocationDTO, date: Date) {
    return this.predictionService.predict(location, date, null);
  }

  getColor(prediction: number) {
    if (prediction < 0.125 ) {
      return 'lime';
    } else if (prediction < 0.375 ) {
      return 'limegreen';
    } else if (prediction < 0.625 ) {
      return 'yellow';
    } else if (prediction < 0.875 ) {
      return 'darkorange';
    } else {
      return 'red';
    }
  }


  min(a: number, b: number) {
    return Math.min(a, b);
  }

  private filterByMaterial(locations: RouteLocationDTO[], material: string): TypedRouteLocationDTO[] {
    const newLocations = new Array<TypedRouteLocationDTO>();
    if (!locations) {
      return;
    }
    locations.forEach(location => {
      if (!location.composition) {
        return;
      }
      location.composition.forEach(composition => {
        if (composition.type === material) {
          newLocations.push(new TypedRouteLocationDTO(
              location.id,
              location.code,
              location.address,
              location.latitude,
              location.longitude,
              composition.type,
              composition.containers,
              composition.totalCapacity,
              composition.lastCollectionDate,
              composition.lastMeasurementDate,
              composition.lastMeasuredValue,
              composition.alpha,
              location.routes
          ));
        }
      });
    });
    return newLocations;
  }

}
