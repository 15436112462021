import { Injectable } from '@angular/core';

import { CRUDGenericService } from 'attcei-bo-fw';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';
import { Observable, Subscriber } from 'rxjs';
import { MapLocationDTO } from 'src/app/classes/dto/MapLocationDTO';

class InternalState {
    constructor(
        public nItemsSent: number
    ) {}

}

@Injectable({
    providedIn: 'root'
  })
export class LocationsService {

    constructor(private service: CRUDGenericService) { }

    get() {
        return new Observable<MapLocationDTO>(observer => {
            this.load(1, observer, true, new InternalState(0));
        });
    }

    load(page: number, observer: Subscriber<MapLocationDTO>, loadNexts: boolean, state: InternalState) {
        const nItemsPerPage = 500;
        this.service.getDataItem('locations/map?pageNumber=' + page + '&pageLenght=' + nItemsPerPage, true)
                .subscribe((data: PaginatedDTO<MapLocationDTO>) => {
                    const nPages = Math.ceil(data.total / nItemsPerPage);
                    if (nPages > 1 && loadNexts) {
                        for (let i = 1; i < nPages; i++) {
                            this.load(i + 1, observer, false, state);
                        }
                    }
                    for (const entry of data.contents ) {
                        state.nItemsSent++;
                        observer.next(entry);
                    }
                    if (state.nItemsSent >= data.total) {
                        console.log('All data was loaded: ' + state.nItemsSent + '/' + data.total);
                        observer.complete();
                    }
                });

    }
}
