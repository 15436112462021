import { Component, OnInit, Input, NgZone } from '@angular/core';
import { LocationsService } from '../locations.service';
import { Subject, combineLatest, Observable, BehaviorSubject } from 'rxjs';
import { RouteLocationDTO } from '../../route/route-builder/dto/RouteLocationDTO';
import { toArray, filter, map, take, debounceTime } from 'rxjs/operators';
import {Router, ActivatedRoute} from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';
import { BaseRouteDTO } from '../../route/route-builder/tabular-builder/dto/BaseRouteDTO';
import { BreadcrumbNavigationService, CRUDGenericService } from 'attcei-bo-fw';
import { MatSelectChange } from '@angular/material/select';
import { LatLngBounds } from '@agm/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { MapLocationDTO } from 'src/app/classes/dto/MapLocationDTO';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
  providers: [LocationsService]
})
export class LocationMapComponent implements OnInit {

  @Input() private map = new BehaviorSubject<google.maps.Map>(null);

  @Input() private locations = new Subject<MapLocationDTO[]>();

  public isLoading = true;
  
  public routes: BaseRouteDTO[];

  public selected: BaseRouteDTO[];

  private markers = {};

  private shown = new BehaviorSubject<number[]>([]);

  private filter = new BehaviorSubject<string>(null);

  private mapBounds = new BehaviorSubject<LatLngBounds>(null);

  private path: string;

  private searchMarker: google.maps.Marker;

  private isFirstLoad = true;

  constructor(
    private locationService: LocationsService, 
    private router: Router,
    private service: CRUDGenericService,
    private nav: BreadcrumbNavigationService,
    private route: ActivatedRoute,
    private zone: NgZone) { 
      var n = router.url.indexOf('?');
      this.path = router.url.substring(0, n != -1 ? n : router.url.length); 
    }



  onChangeFilter(value: string) {
    this.filter.next(value);
  }

  onChange(evt: MatSelectChange) {
    var ids = [];
    var arr = [];
    this.selected.forEach(s => arr = [...arr, ...s.locationId.map(id => +id)]);
    this.selected.forEach(s => ids = [...ids, s.id]);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {routeTemplateId: ids},
      queryParamsHandling: 'merge' 
    });

    this.shown.next(arr);
  }


  ngOnInit() {
    const urlParams = this.route.queryParams.pipe(take(1));
    const _routes = this.get().pipe(map(p => p.contents));

    combineLatest([_routes, urlParams])
    .pipe(filter(arr => !!arr[0] && !!arr[1]))
    .subscribe(arr => {
      this.routes = arr[0];
      const rT = arr[1]['routeTemplateId'];
      this.selected = arr[0].filter(r => typeof rT === 'undefined' || +rT === +r.id || !rT.length || rT.indexOf('' + r.id) !== -1);
      this.onChange(null);
    });

    this.mapBounds.pipe(
      filter(b => !!b),
      debounceTime(300)
    ).subscribe(bounds => {     
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {  
          swLat: bounds.getSouthWest().lat(),
          swLng: bounds.getSouthWest().lng(),
          neLat: bounds.getNorthEast().lat(),
          neLng: bounds.getNorthEast().lng()
        },
        queryParamsHandling: 'merge' 
      });
    });

    this.locationService.get().pipe(
      filter(location => (!!location.latitude) && (!!location.longitude)),
      toArray()
    ).subscribe(this.locations);



    combineLatest([this.locations, this.map, this.shown, urlParams])
    .pipe(filter(arr => !!arr[0] && !!arr[1] && !!arr[2] && !!arr[3]))
    .subscribe(arr => {
      this.isLoading = false;
      
      const params = arr[3];
      const shown = arr[2];
      const m = arr[1];
      const locations = arr[0];
      var bounds;

      //in case we have it on url, already
      if (this.isFirstLoad && params['swLat'] && params['swLng'] && params['neLat'] && params['neLng']) {
        bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(params['swLat'], params['swLng']), 
          new google.maps.LatLng(params['neLat'], params['neLng']));
        this.isFirstLoad = false;
      } else {
        bounds = new google.maps.LatLngBounds();

        locations.filter(l => shown.length === 0 || shown.indexOf(+l.id) !== -1).forEach(l => bounds.extend({lat: l.latitude, lng: l.longitude}));

        //case there is only one location
        var offset = 0.002;     
        var center = bounds.getCenter();                            
        bounds.extend(new google.maps.LatLng(center.lat() + offset, center.lng() + offset));
        bounds.extend(new google.maps.LatLng(center.lat() - offset, center.lng() - offset));
      }

      m.fitBounds(bounds, 0);
      m.panToBounds(bounds);
    });

    const rt = this.router;
    const zn = this.zone;

    combineLatest([this.locations, this.map, this.shown, this.filter])
    .pipe(filter(arr => !!arr[0] && !!arr[1]), debounceTime(100))
    .subscribe(arr => {
      const locations = arr[0];
      const map = arr[1];
      const shown = arr[2];
      const filter = arr[3];

      locations.forEach(location => {
        const id = +location.id;
        const toShow = this.toShow(location, shown, filter);

        if (!this.markers[id]) {
          this.markers[id] = new google.maps.Marker({
            position: {lat: location.latitude, lng: location.longitude},
            title: this.getTitle(location),
            map: toShow ? map: null
          });

          this.markers[id].addListener('click',() => {
            zn.run(() => this.nav.navigateKeepState(['/location/' + location.id]));
          });
        } else {
          this.markers[id].setMap(toShow ? map: null);
        }
      });
    });

  }

  private getTitle(location: MapLocationDTO): string {
    var str = location.code;

    if (location.containers && location.containers.length > 0) {
      str += ": " + location.containers.join(', ')
    }

    return str;
  }

  private toShow(location: MapLocationDTO, shown: number[], filter: string): boolean {
    if (shown.indexOf(location.id) === -1) {
      return false;
    }

    if (!filter) {
      return true;
    }

    const f = filter.toLowerCase();

    return !f || location.code.toLowerCase().startsWith(f) 
      || (location.containers && location.containers.filter(c => c.toLowerCase().startsWith(f)).length > 0)
  }

  onAutocompleteSelected(address: Address) {
    const m = this.map.value;

    if (this.searchMarker) {
      this.searchMarker.setMap(null);
    }

    if (m) {
      if (address.geometry.viewport) {
        m.fitBounds(address.geometry.viewport, 0);
        m.panToBounds(address.geometry.viewport);
      } else if (address.geometry.location) {
        m.setZoom(17);
        m.panTo(address.geometry.location);
      }

      if (address.geometry.location) {
        this.searchMarker = new google.maps.Marker({
          position: address.geometry.location,
          map: m
        });
      }
    }
      
  }

  boundsChange(latLngBounds: LatLngBounds): void {
    this.mapBounds.next(latLngBounds);
  }

  setMap(currentMap: google.maps.Map) {
    this.map.next(currentMap);
  }

  get(): Observable<PaginatedDTO<BaseRouteDTO>> {
      return this.service.getDataItem('builder/routes/template', true);
  }
}
