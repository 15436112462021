import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PredictionService } from '../prediction.service';
import { RoutesService } from '../routes.service';
import { RouteDTO } from './dto/RouteDTO';
import { TypedRouteLocationDTO } from '../dto/TypedRouteLocationDTO';
import { map, flatMap, switchMap } from 'rxjs/operators';
import { Sort, SortDirection } from '@angular/material/sort';
import { DisplayRouteDTO } from './dto/DisplayRouteDTO';
import { GiorDataSource } from '../dto/GiorDataSource';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { ScheduleDTO } from '../dto/ScheduleDTO';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RouteDetailsComponent } from '../route-details/route-details.component';
import { RouteDetailsDTO } from '../route-details/dto/RouteDetailsDTO';
import { ScheduledLocationDTO } from '../dto/ScheduledLocationDTO';
import { ScheduledRouteLocationDTO } from '../dto/ScheduledRouteLocationDTO';
import { BaseRouteDTO } from './dto/BaseRouteDTO';

class RouteSort implements Sort {
  active: string;
  direction: SortDirection;
  constructor(active: string, direction: SortDirection) {
    this.active = active;
    this.direction = direction;
  }
}

@Component({
  selector: 'app-tabular-builder',
  templateUrl: './tabular-builder.component.html',
  styleUrls: ['./tabular-builder.component.scss'],
  providers: [PredictionService, RoutesService]
})
export class TabularBuilderComponent implements OnInit {

  public displayedColumns: string[] = ['scheduleBtn', 'viewBtn', 'code', 'nLocations', 'sumExpectedCapacity',
  'nLocationsP0', 'nLocationsP25', 'nLocationsP50', 'nLocationsP75', 'nLocationsP100', 'fvT0', 'fvT1', 'fvT2'];

  public routes = new GiorDataSource<DisplayRouteDTO>();

  private sort: Subject<Sort> = new BehaviorSubject<Sort>(new RouteSort('code', 'asc'));

  @Input() private date: BehaviorSubject<Date>;

  @Input() private density: Subject<number>;

  @Input() private updates: BehaviorSubject<Date>;

  @Input() private locations: Subject<TypedRouteLocationDTO[]>;

  @Input() private scheduledLocations: BehaviorSubject<Map<string, ScheduledLocationDTO>>;

  @Output() private scheduleEvents = new EventEmitter<ScheduleDTO>();

  constructor(private routeService: RoutesService,
              private predictionService: PredictionService,
              private dialog: MatDialog) { }

  ngOnInit() {
    const allRoutes = this.updates.pipe(
      switchMap(d => this.routeService.get()),
      map(paginated => paginated.contents)
    );

    const locationMap = this.locations
      .pipe(map(locations => {
        const m = new Map<number, TypedRouteLocationDTO>();
        locations.forEach(l => m.set(l.id, l));
        return m;
      }));

    const completeRoutes = combineLatest([locationMap, allRoutes])
        .pipe(map(arr => this.combineIntoRoutes(arr[0], arr[1])));

    const unsortedRoutes = combineLatest([completeRoutes, this.date, this.scheduledLocations, this.density])
        .pipe(
          map(arr => this.mapToDisplayRoute(arr[0], arr[1], arr[2], arr[3])),
          map(routes => routes.filter(r => r.nLocations > 0)));

    combineLatest([unsortedRoutes, this.sort])
          .pipe(map(arr => {
            const data = arr[0].slice();
            data.sort(this.dynamicSort(arr[1]));
            return data;
          })).subscribe(this.routes.getSubject());
  }

  private dynamicSort(sort: Sort) {
    return (a: any, b: any) => {
      let sortValue = 0;
      if (typeof a[sort.active] === 'string') {
        sortValue = a[sort.active] > b[sort.active] ? 1 : -1;
      } else {
        sortValue = a[sort.active] - b[sort.active];
      }

      return sort.direction === 'asc' ? sortValue : -1 * sortValue;
    };
  }

  onSortData(sort: Sort) {
      this.sort.next(sort);
  }


  getColor(prediction: number) {
    if (prediction < 0.25 ) {
      return 'lime';
    } else if (prediction < 0.5 ) {
      return 'limegreen';
    } else if (prediction < 0.75 ) {
      return 'orange';
    } else if (prediction < 0.875 ) {
      return 'darkorange';
    } else {
      return 'red';
    }
  }

  onSchedule(route: RouteDTO) {
      this.scheduleEvents.emit(new ScheduleDTO(null,
        route.id, route.code, this.date.value, true,
        Object.assign([], route.locations)
          .map(l => new ScheduledRouteLocationDTO(l))));
  }

  openDetails(route: RouteDTO) {
    const config = new MatDialogConfig<RouteDetailsDTO>();
    config.data = new RouteDetailsDTO(route.id, route.code, this.date.value, route.locations, this.scheduledLocations);
    const dialogRef = this.dialog.open(RouteDetailsComponent, config);
    dialogRef.componentInstance.scheduleEvents
      .subscribe(e => this.scheduleEvents.emit(e));
  }

  private mapToDisplayRoute(routes: RouteDTO[], date: Date, scheduled: Map<string, ScheduledLocationDTO>, density: number): DisplayRouteDTO[] {
    const t = date;
    const t1 = new Date(t.getTime() + this.predictionService.MILIS_IN_DAY);
    const t2 = new Date(t.getTime() + 2 * this.predictionService.MILIS_IN_DAY);

    return routes.map(route => {
      const dr = new DisplayRouteDTO(route.id, route.code, route, route.locations.length, 0.0, 0, 0, 0, 0, 0, 0, 0.0, 0.0, 0.0);
      route.locations.forEach(location => {
        let schDate: Date;
        const sch = scheduled.get(location.type + '_' + location.id);
        if (sch) {
          schDate = sch.schedule.date;
        }

        const prediction = this.predictionService.predict(location, t, schDate);
        const prediction1 = this.predictionService.predict(location, t1, schDate);
        const prediction2 = this.predictionService.predict(location, t2, schDate);

        dr.fvT0 = (dr.nContainers * dr.fvT0 + (1 - prediction) * location.containers) / (dr.nContainers + location.containers);
        dr.fvT1 = (dr.nContainers * dr.fvT1 + (1 - prediction1) * location.containers) / (dr.nContainers + location.containers);
        dr.fvT2 = (dr.nContainers * dr.fvT2 + (1 - prediction2) * location.containers) / (dr.nContainers + location.containers);

        dr.nContainers += location.containers;
        dr.sumExpectedCapacity += ((location.totalCapacity * prediction) / 1000) * density;

        if (prediction < 0.125 ) {
          dr.nLocationsP0++;
        } else if (prediction < 0.375 ) {
          dr.nLocationsP25++;
        } else if (prediction < 0.625 ) {
          dr.nLocationsP50++;
        } else if (prediction < 0.875 ) {
          dr.nLocationsP75++;
        } else {
          dr.nLocationsP100++;
        }
      });
      return dr;
    });
  }

  private combineIntoRoutes(locations: Map<number, TypedRouteLocationDTO>, broutes: BaseRouteDTO[]): RouteDTO[] {
    const noRoute = new RouteDTO(null, 'Não associado', []);
    const associated = new Array<number>();

    const routes = broutes.map(r => {
      const route = new RouteDTO(r.id, r.code, r.locationId
        .map(id => locations.get(id))
        .filter(l => !!l));

      route.locations.forEach(l => associated.push(l.id));

      return route;
    });

    Array.from(locations.keys())
      .filter(l => associated.indexOf(l) === -1)
      .map(id => locations.get(id))
      .filter(l => !!l)
      .forEach(l => noRoute.locations.push(l));

    routes.push(noRoute);

    return routes;
  }

}
