import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { CRUDGenericService, SessionHandler } from 'attcei-bo-fw';

@Component({
  selector: 'app-statistics-reports',
  templateUrl: './reports.component.html',
  styleUrls: []
})
export class StatisticsReportsComponent implements OnInit {

  public reports;

  public panelOpenState: boolean[] = [];

  public isLoading = false;

  constructor(private service: CRUDGenericService, private httpClient: HttpClient) { }

  ngOnInit() {
    this.service.getDataList('jreport', true).subscribe(v => {
      this.reports = v.contents;
    });
  }

  public download(id: number, ext: string) {
    this.isLoading = true;
    
    this.httpClient.get(environment.functionsUrl + '/jreport/generate/' + ext + '/' + id, { 
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + SessionHandler.getSession().accessToken}) 
    }).subscribe(
      data => {
        this.isLoading = false;
        this.downloadFile(data, ext);
      },
      error => {
        this.isLoading = false;
        console.log('Error downloading the file.', error);
      }
    );
  }
  
  downloadFile(data: any, ext: string) {
    const a = document.createElement('a')
    const objectUrl = URL.createObjectURL(data)
    a.href = objectUrl
    a.download = 'report.' + ext;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }
}
