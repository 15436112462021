import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CRUDGenericService } from 'attcei-bo-fw';
import { Subject } from 'rxjs';
import { filter, finalize, toArray } from 'rxjs/operators';
import { LocationDTO } from './LocationDTO';
import { LocationsService } from './locations.service';

@Component({
  selector: 'app-route-template-edit',
  templateUrl: './route-template-edit.component.html',
  styleUrls: ['./route-template-edit.component.scss']
})
export class RouteTemplateEditComponent implements OnInit {

  public id: number;

  public locations = new Subject<LocationDTO[]>();

  public isLoading: boolean;

  public inRoute = [];

  constructor(private locationService: LocationsService, 
    private route: ActivatedRoute, 
    private service: CRUDGenericService,
    private snackBar: MatSnackBar) { }
  


  ngOnInit() {
    this.id = Number.parseInt(this.route.snapshot.paramMap.get('id'));
    
    this.isLoading = true;

    this.service.getDataItem('routes/template/composition', true, this.id)
      .subscribe(v => this.inRoute = v.locationId);

    this.locationService.get().pipe(
      filter(location => (!!location.latitude) && (!!location.longitude)), 
      toArray(),
      finalize(() => this.isLoading = false)).subscribe(this.locations);
  }

  save() {
    this.service.putData('routes/template/composition', this.id, {locationId: this.inRoute}, true)
      .subscribe(ok => {
        this.snackBar.open('Rota actualizada', '', { duration: 2000 })
      }, nok => {
        this.snackBar.open('Ocurreu um erro ao guardar os dados', '', { duration: 5000 })
      });
  }

}
