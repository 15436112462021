import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CRUDGenericService, ExcelService } from 'attcei-bo-fw';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodeGeneratorDTO } from 'src/app/classes/dto/CodeGeneratorDTO';
import { ContainerTypeDTO } from 'src/app/classes/dto/ContainerTypeDTO';
import { LocalityDTO } from 'src/app/classes/dto/LocalityDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ParishDTO } from 'src/app/classes/dto/ParishDTO';

@Component({
  selector: 'app-container-upload',
  templateUrl: './container-upload.component.html',
  styleUrls: ['./container-upload.component.scss']
})
export class ContainerUploadComponent implements OnInit {

  displayedColumns: string[] = ['code', 'rfid', 'latitude', 'longitude', 'address', 'postalCode', 'parish', 'locality', 'type', 'containerType'];

  headers = [[
    'Código Contentor',
    'RFID',
    'Latitude',
    'Longitude',
    'Morada',
    'Código Postal',
    'Freguesia',
    'Localidade',
    'Tipo',
    'Tipo de Contentor'
  ]];

  public dataSource = [];

  public containerTypes:ContainerTypeDTO[] = [];

  public localities:LocalityDTO[] = [];

  public parishs:ParishDTO[] = [];

  public locationCodeGenerators:CodeGeneratorDTO[] = [];

  public selectedLocationGenerator = null;
  
  public containerCodeGenerators:CodeGeneratorDTO[] = [];
  
  public selectedContainerGenerator = null;

  constructor(private snackBar: MatSnackBar, private excel: ExcelService, private crud: CRUDGenericService) { }

  ngOnInit() {
    this.crud.getDataList('codegenerator', true).pipe(map(p => p.contents)).subscribe((generators: CodeGeneratorDTO[]) => {
      this.locationCodeGenerators = generators.filter(g => g.type == 'LOCATION');
      this.selectedLocationGenerator = this.locationCodeGenerators.length > 0 ? this.locationCodeGenerators[0].id : null;

      this.containerCodeGenerators = generators.filter(g => g.type == 'CONTAINER');
      this.selectedContainerGenerator = this.containerCodeGenerators.length > 0 ? this.containerCodeGenerators[0].id : null;
    })

    this.crud.getDataList('containertype', true).pipe(map(p => p.contents)).subscribe((types: ContainerTypeDTO[]) => {
      this.containerTypes = types;
    })

    this.crud.getDataList('locality', true).pipe(map(p => p.contents)).subscribe((localities: LocalityDTO[]) => {
      this.localities = localities;
    })

    this.crud.getDataList('parish', true).pipe(map(p => p.contents)).subscribe((parishs: ParishDTO[]) => {
      this.parishs = parishs;
    })
  }

  public handleFileInput(files: FileList) {
    this.excel.importExcelFileAsArray(files.item(0), (arr) => {
      arr.shift(); //remove header

      this.dataSource = arr.map(val => { return {
        "code": this.sanitize(val[0]), 
        "rfid": this.sanitize(val[1]), 
        "latitude": val[2], 
        "longitude": val[3], 
        "address": this.sanitize(val[4]),
        "postalCode": this.sanitize(val[5]),
        "parish": this.sanitize(val[6]),
        "locality": this.sanitize(val[7]),
        "type": this.sanitize(val[8]),
        "containerType": this.sanitize(val[9]),
      }}).filter(a => a.address || a.code || a.rfid || a.latitude || a.longitude || a.postalCode);
    });
  }

  private sanitize(val: string): string {
    return val ? ("" + val).trim() : null
  }

  public downloadTemplate() {
    this.excel.exportArrayAsExcelFile(this.headers, 'template.xlsx');
  }

  public isItemValid(item: any): boolean {
    return this.isCodeValid(item.code) 
    && this.isCodeValid(item.rfid)
    && this.isLatLonValid(item.latitude, item.longitude)
    && this.isAddressValid(item.address)
    && this.isPostalCodeValid(item.postalCode)
    && this.isParishValid(item.parish) !== 0
    && this.isLocalityValid(item.locality) !== 0
    && this.isTypeValid(item.type)
    && this.isContainerTypeValid(item.containerType) !== 0;
  }

  public isCodeValid(code: any): boolean {
    return code == null || code.length < 20;
  }

  public isParishValid(parish: any): number {
    return this.parishs.find(p => p.name.toUpperCase() === parish.toUpperCase())?.id;
  }

  public isLocalityValid(locality: any): number {
    return this.localities.find(p => p.name.toUpperCase() === locality.toUpperCase())?.id;
  }

  public isTypeValid(type: any): boolean {
    return ["RESTAURANT", "HABITATION", "PUBLIC", "OTHER"].findIndex(p => p.toUpperCase() === type.toUpperCase()) !== -1;
  }

  public isContainerTypeValid(type: any): number {
    return this.containerTypes.find(p => p.name.toUpperCase() === type.toUpperCase())?.id;
  }

  public isRfidValid(rfid: any): boolean {
    return rfid != null && rfid.length < 100;
  }
  public isLatLonValid(latitude: any, longitude: any): boolean {
    return latitude != null && !isNaN(latitude) && latitude > 36.838268541 && latitude < 42.280468655 
      && longitude != null && !isNaN(longitude) && longitude > -9.52657060387 && longitude < -6.3890876937 ;
  }

  public isAddressValid(addr: any): boolean {
    return addr != null && addr.length < 255;
  }

  public isPostalCodeValid(postalCode: any): boolean {
    return postalCode == null || (postalCode.length < 255 && /\d{4}(-\d{3})?/.test(postalCode));
  }

  private hasAnyNullContainerCode(arr: any[]): boolean {
    return arr.reduce((prev, curr) => prev || curr.code == null, false);
  }

  public isValid(): boolean {
    return this.selectedLocationGenerator != null 
    && this.dataSource.length > 0 
    && this.dataSource.reduce((prev, curr) => prev && this.isItemValid(curr), true)
    && (!this.hasAnyNullContainerCode(this.dataSource) || this.selectedContainerGenerator != null);
  }
  
  public save() {
    const obj = {
      containerGeneratorId: this.selectedContainerGenerator,
      locationGeneratorId: this.selectedLocationGenerator,
      containers: this.dataSource.map(ds => { return {
        code: ds.code,
        rfid: ds.rfid,
        latitude: ds.latitude,
        longitude: ds.longitude,
        address: ds.address,
        postalCode: ds.postalCode,
        parishId: this.isParishValid(ds.parish),
        localityId: this.isLocalityValid(ds.locality),
        containerTypeId: this.isContainerTypeValid(ds.containerType),
        type: ds.type
      }})
    };

    this.crud.postData("container/upload",obj, true).subscribe(ok => {
      this.dataSource = [];
      this.snackBar.open('Contentores registados', '', { duration: 2000 })
    }, nok => {
      if (nok.status == 409) {
        this.snackBar.open('Não foi possivel guardar, possui dados duplicados', '', { duration: 15000, panelClass: ['warn'] });
      } else {
        this.snackBar.open('Não foi possivel guardar, tente mais tarde', '', { duration: 15000, panelClass: ['warn'] });
      }
    });
  }

}
