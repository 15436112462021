import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { CRUDGenericService } from 'attcei-bo-fw';






@Component({
  selector: 'app-test-dev',
  templateUrl: './test-dev.component.html',
  styleUrls: ['./test-dev.component.scss']
})
export class TestDevComponent implements OnInit {

 
  constructor(private authService:CRUDGenericService) { }

  ngOnInit() {
    this.buildTestData();
  }

  buildTestData(){
    var mapTest:Map<string,any> = new Map<string,any>();
    mapTest.set("key","value");
    mapTest.set("key2","value2");
    mapTest.set("key3","value3");

    console.log("o Mapa -> ", mapTest);
    

    this.authService.getDataItem('user/current/wprofile',true).subscribe(
      data => {
        console.log("Recebido do serviço", data['profile']['email']);
      }
    )
  
  }


  buildTestObject(){
    
  }

  chosen(id:number){
    console.log("CHOSEN -> "+id);
  }

}
