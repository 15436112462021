import { Component, OnInit } from '@angular/core';
import { TrailerScreenHandler } from 'src/app/handlers/TrailerScreenHandler';
import screen from '../../../../assets/TrailerScreen.json'


@Component({
  selector: 'app-trailer-list',
  templateUrl: './trailer-list.component.html',
  styleUrls: ['./trailer-list.component.scss']
})
export class TrailerListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(public screenHandler: TrailerScreenHandler) { }

  ngOnInit() {
  }

}
