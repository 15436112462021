import { Injectable } from '@angular/core';
import { TypedRouteLocationDTO } from './dto/TypedRouteLocationDTO';


@Injectable()
export class PredictionService {
    public SECONDS_IN_DAY = 24 * 3600;

    public MILIS_IN_DAY = this.SECONDS_IN_DAY * 1000;

    predict(location: TypedRouteLocationDTO, date: Date, scheduled: Date): number {
        const schDate = scheduled ? scheduled.getTime() : 0;
        const lngDate = date.getTime();

        if (schDate >= lngDate || location.lastCollectionDate >= lngDate) {
            return 0.0;
        }

        if (schDate) {
            // if there is a schedule, use it or else we can have weird situation if users report after the schedule date
            return Math.min(location.alpha * (lngDate - schDate) / this.MILIS_IN_DAY, 1);
        }

        if (location.lastMeasurementDate >= lngDate) {
            return location.lastMeasuredValue / 100;
        }

        return Math.min(location.lastMeasuredValue / 100 +
            location.alpha * (lngDate - location.lastMeasurementDate) / this.MILIS_IN_DAY, 1);
    }

}
