import { Observable, of } from "rxjs";
import { AbstractScreenHandler, CRUDGenericService, TypeValidatorService } from 'attcei-bo-fw';
import { LocationHistoryDTO } from '../classes/dto/LocationHistoryDTO';
import { ScreenHandler } from 'attcei-bo-fw/lib/screen/interfaces/ScreenHandler';
import { Injectable } from '@angular/core';
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { Sort } from '@angular/material/sort';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';

@Injectable({
    providedIn:'root'
})

export class LocationHistoryScreenHandler extends AbstractScreenHandler<LocationHistoryDTO, LocationHistoryDTO> implements ScreenHandler<LocationHistoryDTO, LocationHistoryDTO>{
    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }
    
    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<LocationHistoryDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }

    post(item: LocationHistoryDTO):Observable<LocationHistoryDTO> {
        return of(null);
    }

    update(item: LocationHistoryDTO):Observable<LocationHistoryDTO> {
        return of(null);
    }

    delete(item: LocationHistoryDTO):Observable<LocationHistoryDTO> {
        return of(null);
    }

    getItem(id: number):Observable<LocationHistoryDTO> {
        return of(null);
    }

    getURL(): string {
        return "locationhistory"
    }

    getId(item: LocationHistoryDTO): number {
        return null;
    }
    
    protected getUpdateTypeName(): string {
        return "LocationHistoryDTO";
   
    }    
    
    protected getCreateTypeName(): string {
        return "LocationHistoryDTO";
    }
}