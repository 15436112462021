import { Component, OnInit } from '@angular/core';
import { NewsScreenHandler } from 'src/app/handlers/NewsScreenHandler';
import screen from '../../../../assets/NewsScreen.json';


@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(public screenHandler: NewsScreenHandler) { }

  ngOnInit() {
    console.log(this.screenStructure);
  }

}