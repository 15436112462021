import { Component, OnInit, Input } from '@angular/core';
import { RouteDTO } from 'src/app/classes/dto/RouteDTO';
import { LocationDTO } from 'src/app/classes/dto/LocationDTO';
import { BehaviorSubject, Subject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MapRouteDTO, MapPointDTO, Coordinate, CRUDGenericService } from 'attcei-bo-fw';
import { WaypointStatus } from 'src/app/classes/dto/WaypointStatus';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BuilderDialogComponent } from '../route-builder/builder-dialog/builder-dialog.component';

@Component({
  selector: 'app-route-completed-map',
  templateUrl: './route-completed-map.component.html',
  styleUrls: ['./route-completed-map.component.scss']
})
export class RouteCompletedMapComponent implements OnInit {

  public innerRoute = new BehaviorSubject<RouteDTO>(null);

  public takenRoute = new BehaviorSubject<Coordinate[]>(null);

  id: number;

  public innerLocations = new BehaviorSubject<LocationDTO[]>([]);

  public routes$ = new BehaviorSubject<MapRouteDTO[]>([]);

  public points$ = new BehaviorSubject<MapPointDTO[]>([]);

  private localStatus = new Map<number, WaypointStatus>();

  constructor(private router: Router, private crud: CRUDGenericService,
    private snackBar: MatSnackBar, private dialog: MatDialog) { }

  @Input() set route(item: RouteDTO) {
    if (item != null) {
      item.waypoints.forEach(wp => this.localStatus.set(wp.locationId, wp.status));
      this.innerRoute.next(item);
    }
  }

  @Input() set locations(items) {
    this.innerLocations.next(items);
  }

  ngOnInit() {
    this.innerRoute.pipe(
      map(r => [
        new MapRouteDTO('Rota Percorrida', 'red', 3, r.pathTaken, false, 'FORWARD_CLOSED_ARROW', '100px', 3, 0.9),
        new MapRouteDTO('Rota Planeada', 'blue', 2, r.pathPlanned, false, 'FORWARD_CLOSED_ARROW', '100px', 2, 0.6)
      ])
    ).subscribe(this.routes$);

    this.innerRoute.pipe(map(r => r.pathTaken)).subscribe(this.takenRoute);

    this.innerRoute.pipe(map(r => r.routeTemplateId)).subscribe(id => this.id = id);

    combineLatest([this.innerRoute, this.innerLocations]).pipe(
      map(arr => arr[1]),
      map(ls => ls.map(l => new MapPointDTO(
        l.code, 
        this.getIcon(this.localStatus.get(l.id)), 
        l.latitude, 
        l.longitude,
        () => {
          console.log(l.id);
          this.router.navigate(['/location/' + l.id]);
        })))
    ).subscribe(this.points$);
  }

  hasPathTaken(): boolean {
    return this.takenRoute.value != null && this.takenRoute.value.length > 1;
  }

  
  openGenericDialog(title: string, message: string, ...btns: string[]): Observable<any> {
    const config = new MatDialogConfig<any>();
    config.data = {title, message, btns};
    const dialogRef = this.dialog.open(BuilderDialogComponent, config);
    return dialogRef.afterClosed();
  }


  savePathTaken() {
    this.openGenericDialog(
      'Guardar rota',
      'Deseja substituir a rota pré-definida para o circuito base?',
      'Cancelar', 'Guardar')
      .subscribe(result => {
        if (result === 'Guardar') {
          this.crud.putData('routes/templates/waypoint', this.id, this.takenRoute.value, true).subscribe(ok => {
            this.snackBar.open('Circuito actualizado', '', { duration: 2000 })
          }, nok => {
            this.snackBar.open('Ocurreu um erro ao guardar os dados', '', { duration: 5000 })
          })
        }
      });
  }

  getIcon(status): string {
    if (status ==  'COLLECTED') {
      return  '../../../assets/images/green_marker.png';
    } else if (status ==  'PENDING') {
      return '../../../assets/images/yellow_marker.png';
    } else {
      return '../../../assets/images/red_marker.png';
    }
  }


}
