import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteWaypointScreenHandler } from 'src/app/handlers/RouteWaypointScreenHandler';
import screen from '../../../../assets/RouteWaypointScreen.json'


@Component({
  selector: 'app-route-completed-locations',
  templateUrl: './route-completed-locations.component.html',
  styleUrls: ['./route-completed-locations.component.scss']
})
export class RouteCompletedLocationsComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(private route:ActivatedRoute, public screenHandler: RouteWaypointScreenHandler) { }

  @Input() uid: string;

  public filter: any = {};
  
  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');

    this.filter = {routeId: Number.parseInt(this.uid)};
  }

}