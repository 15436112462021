import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CollectionMessageScreenHandler } from 'src/app/handlers/CollectionMessageScreenHandler';
import screen from '../../../../assets/CollectionMessageScreen.json';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss']
})
export class MessageDetailComponent implements OnInit {

  public eventFilter: any;

  public screenStructure = JSON.parse(JSON.stringify(screen))

  @Input() uid: string;

  constructor(private route: ActivatedRoute, public screenHandler: CollectionMessageScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');

    this.eventFilter = {container: Number.parseInt(this.uid)};
  }


}
