import { RouteDTO } from '../classes/dto/RouteDTO';
import { AbstractScreenHandler, CRUDGenericService, TypeValidatorService } from 'attcei-bo-fw';
import { ScreenHandler } from 'attcei-bo-fw/lib/screen/interfaces/ScreenHandler';
import { Observable } from 'rxjs';
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { Injectable } from '@angular/core';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';
import { Sort } from '@angular/material/sort';

@Injectable({
    providedIn:'root'
})

export class RouteScreenHandler extends AbstractScreenHandler<RouteDTO, RouteDTO> implements ScreenHandler<RouteDTO, RouteDTO>{
 
    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }

    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<RouteDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }    
    
    post(item: RouteDTO): Observable<RouteDTO> {
        throw new Error("Method not implemented.");
    }
    update(item: RouteDTO): Observable<RouteDTO> {
        throw new Error("Method not implemented.");
    }
    delete(item: RouteDTO): Observable<any> {
        throw new Error("Method not implemented.");
    }
    getItem(id: number): Observable<RouteDTO> {
        return this.service.getDataItem(this.getURL(), true, id);
    }
    getURL(): string {
        return "route";
    }
    getId(item: RouteDTO): number {
        return item.id;
    }

    protected getUpdateTypeName(): string {
        return "RouteDTO";
    }
    protected getCreateTypeName(): string {
        return "RouteDTO";
    }
    
}