import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VehicleDTO } from "../classes/dto/VehicleDTO";
import { CRUDGenericService } from "attcei-bo-fw";
import { ScreenHandler } from "attcei-bo-fw/lib/screen/interfaces/ScreenHandler";
import { TypeValidatorService } from "attcei-bo-fw/";
import { AbstractScreenHandler } from "attcei-bo-fw";
import { BasicVehicleDTO } from "../classes/dto/BasicVehicleDTO";
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { Sort } from '@angular/material/sort';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';


@Injectable({
    providedIn:'root'
})

export class VehicleScreenHandler extends AbstractScreenHandler<BasicVehicleDTO, VehicleDTO> implements ScreenHandler<BasicVehicleDTO, VehicleDTO>{
    
    protected getUpdateTypeName(): string {
        return 'VehicleDTO';
    }
    protected getCreateTypeName(): string {
        return 'BasicVehicleDTO';
    }

    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }

    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<VehicleDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }
    post(item: BasicVehicleDTO): Observable<VehicleDTO> {
        console.log("Recebi no post -> ", item);
        var dto: BasicVehicleDTO = super.parse(item);
        var res: Observable<VehicleDTO> = this.service.postData(this.getURL(),dto,true);
        console.log("result -> ", res);
        return res;
    }
    update(item: VehicleDTO): Observable<VehicleDTO> {
        console.log("Recebi no update -> ", item);
        var dto: VehicleDTO = super.parse(item);
        var res: Observable<VehicleDTO> = this.service.putData(this.getURL(), dto.id, dto, true);
        console.log("parsed -> ", res);
        return res;
    }
    delete(item: VehicleDTO):Observable<any> {
        return this.service.delete(this.getURL(), item.id, true);
    }
    getItem(id: number): Observable<VehicleDTO> {
        return this.service.getDataItem(this.getURL(), true, id);
    }
   
    getURL(): string {
        return "vehicle";
    }

    getId(item: VehicleDTO): number {
        return item.id;
    }
}