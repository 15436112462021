import { Component, OnInit, Input } from '@angular/core';
import { GiorDataSource } from '../route/route-builder/dto/GiorDataSource';
import { Observable } from 'rxjs';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { EventDetailsComponent } from '../event-details/event-details.component';
import { CRUDGenericService } from 'attcei-bo-fw';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  private translations = {COLLECTION:'Recolha', DIRTY: 'Sujo', MEASUREMENT: 'Medição', DAMAGED: 'Danificado', STOLEN: 'Furto', OTHER: 'Outro'};

  public dataSource = new GiorDataSource<any>();

  public columns = ['viewBtn', 'type', 'location', 'container', 'material', 'date', 'observation', 'filling'];

  public isEmpty: boolean;

  @Input()
  private filter: any;

  constructor(
    private dialog: MatDialog,
    private service: CRUDGenericService) { }

  ngOnInit() {
    const filtersrt = JSON.stringify(this.filter);
    this.service.getDataItem('events?filter=' + filtersrt + '&pageOrderName=date&pageOrderDirection=DESC', true)
      .pipe(
        map(t => t.contents),
        tap(t => this.isEmpty = t.length === 0))
      .subscribe(this.dataSource.getSubject());

  }

  openDialog(event: any): Observable<any> {
    const config = new MatDialogConfig<any>();
    config.data = {event};
    const dialogRef = this.dialog.open(EventDetailsComponent, config);
    return dialogRef.afterClosed();
  }

  getTypeTranslation(name: string): string {
    return this.translations[name];
  }

}
