import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './builder-dialog.component.html',
  styleUrls: ['./builder-dialog.component.scss']
})
export class BuilderDialogComponent implements OnInit {

  public title: string;

  public message: string;

  public btns: string[];

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = data.title;
    this.message = data.message;
    this.btns = data.btns;
  }

  ngOnInit() {
  }

}
