import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteScreenHandler } from 'src/app/handlers/RouteScreenHandler';
import { RouteDTO } from 'src/app/classes/dto/RouteDTO';
import { LocationScreenHandler } from 'src/app/handlers/LocationScreenHandler';
import { LocationDTO } from 'src/app/classes/dto/LocationDTO';
import { PaginationState } from 'attcei-bo-fw';
import { Subject, BehaviorSubject, Observable, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedDTO } from '../route-builder/dto/PaginatedDTO';

@Component({
  selector: 'app-route-completed-detail',
  templateUrl: './route-completed-detail.component.html',
  styleUrls: ['./route-completed-detail.component.scss']
})
export class RouteCompletedDetailComponent implements OnInit {

  @Input() uid: string;

  public routeDTO: RouteDTO;
  public locations: LocationDTO[];
  public isLoading = true;

  public route$ = new BehaviorSubject<RouteDTO>(null);

  public locations$ = new BehaviorSubject<LocationDTO[]>([]);;


  constructor(private route:ActivatedRoute, 
              private routeScreenHandler: RouteScreenHandler,
              private locationScreenHandler: LocationScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');
    console.log(this.uid);

    this.loadRoute();

  }

  loadRoute(){
    this.routeScreenHandler.getItem(+this.uid).subscribe(
      data => {
        //route loaded
        this.routeDTO = data;
        this.route$.next(data);

        //loading locations
        var ids =  this.routeDTO.waypoints.map( w => w.locationId);

        //loading waypoint locations
        this.loadListData(ids).subscribe(data => {
            this.locations = data.contents;
            this.locations$.next(data.contents);
            this.isLoading = false;
            console.log(this.locations);
          });
      }
    )
  }

  private loadListData(ids: number[]): Observable<PaginatedDTO<LocationDTO>> {
    // when there are no ids, just return
    if (!ids || ids.length === 0) {
        return of(new PaginatedDTO([], 0));
    }
      
    //remove duplicates
    const _filtered = ids.filter((item, index) => ids.indexOf(item) === index);

    //if there are too many ids for filtering we can get a server error
    if (_filtered.length > 200) {
        const inpt: Observable<any>[] = this.chunkArray(_filtered, 200).map(chunk => this.loadListData(chunk));
        return forkJoin(inpt)
            .pipe(map(pages => {
                    const merged = [];
                    pages.forEach((pg: any)  => pg.contents.forEach(itm => merged.push(itm)));
                    return new PaginatedDTO(merged, merged.length);
                }));
    }
    
    return this.locationScreenHandler.loadListData({"id": ids}, new PaginationState(0, 1), null);
  }

  chunkArray(myArray: any[], chunk_size: number): any[][]{
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = myArray.slice(index, index+chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

    return tempArray;
  }

}
