import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  templateUrl: './schedule-editor-dialog.component.html',
  styleUrls: ['./schedule-editor-dialog.component.scss']
})
export class ScheduleEditorDialogComponent implements OnInit {

  public schedule: FormGroup;

  public btn = null;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    this.schedule = new FormGroup({
      code: new FormControl(data.schedule.code, Validators.required),
      date: new FormControl(data.schedule.date, Validators.required),
    });
  }

  onSave() {
    const value = this.schedule.value;
    console.log(value);
    this.data.schedule.code = value.code;
    this.data.schedule.date = value.date;
  }

  ngOnInit() {
  }

}
