import { Component } from '@angular/core';
import { SessionHandler, CRUDGenericService } from 'attcei-bo-fw';
import { Router } from '@angular/router';
import menuStructure from '../assets/Navigation.json';
import { Session } from 'attcei-bo-fw/lib/classes/Session';
import { environment } from 'src/environments/environment';
import { UserScreenHandler } from './handlers/UserScreenHandler';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{

ptitle = 'app';

public isLoggedIn = false;
public navigation = AppComponent.applyTemplate(JSON.parse(JSON.stringify(menuStructure)), environment);
public session:Session;
public username:string;
public userId: number;

//create a function that returns an object where all strings matchig the pattern {{xxx}} are replaced by the value of the property xxx in the backing object
//the function should be recursive to allow for nested objects
//if its a full match, it should replace the string with corresponding value

static applyTemplate(obj, backingObject){
  if(obj instanceof Array){
    for(let i = 0; i < obj.length; i++){
      obj[i] = AppComponent.applyTemplate(obj[i], backingObject);
    }
  }else if(obj instanceof Object){
    for(let key in obj){
      obj[key] = AppComponent.applyTemplate(obj[key], backingObject);
    }
  }else if(typeof obj == 'string') {
    let matches = obj.match(/{{(.+?)}}/g);
    if(matches){
      matches.forEach(match =>{
        let key = match.substring(2, match.length - 2);
        obj = backingObject[key];
      })
    }
  }
  return obj;
}



constructor(private sessionHandler: SessionHandler,
            private router: Router,
            private userScreenHandler: UserScreenHandler){}

  ngOnInit(){
    console.log(this.navigation);
    this.loadApiURL();

    this.session = JSON.parse(localStorage.getItem(SessionHandler.SESSION));
    this.isLoggedIn = this.sessionHandler.isLoggedIn();
    if(this.session.user.username){
      this.userId = this.session.user.id;
      this.username = this.session.user.username;
    }

    this.sessionHandler  .logoutEvent.subscribe(() => {
      this.isLoggedIn = false;

      //redirecting back to login
      this.router.navigate(['/']);
    })
  }

  loadApiURL(){
    localStorage.setItem(CRUDGenericService.ENDPOINT_KEY, environment.apiUrl);
  }
  
  performLogin(login){
    this.isLoggedIn = true;
    this.loadUserName();
  }

  loadUserName(){
    //else, there is no username stored in session
    this.userScreenHandler.getCurrentUserProfile().subscribe(
      data =>{
        this.username = data.name;

        //store it
        this.userId = data.id;
        this.session.user.id = this.userId;
        this.session.user.username = this.username;
        localStorage.setItem(SessionHandler.SESSION, JSON.stringify(this.session));

        this.proceedLogin();
      },
      error =>{
        console.log("Couldn't get user information");
        this.proceedLogin();
      }
    )
  }

  proceedLogin(){
    this.router.navigate(['/welcome']);
  }
}
