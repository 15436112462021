import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CronService {

  
  constructor() { }


  public translateWeekDay(input: string): any {
    const option = {
      'MON': 'Segunda',
      'TUE': 'Terça',
      'WED': 'Quarta',
      'THU': 'Quinta',
      'FRI': 'Sexta',
      'SAT': 'Sábado',
      'SUN': 'Domingo'
    };
    return option[input];
  }

  public getExpression(input: string): any {
    const dailyRegex = /0\s(\d+)\s(\d+)\s\*\s\*\s\?/;
    const weeklyRegex = /0\s(\d+)\s(\d+)\s\?\s\*\s([\w,]+)/;
    const monthlyRegex = /0\s(\d+)\s(\d+)\s([\d,]+)\s\*\s\?/;
    var rt;

    if (input !== null && dailyRegex.test(input)) {
      const result = dailyRegex.exec(input);
      const d = this.getDate(result);
      rt = {type: 'DAILY', minute: "" + d.getMinutes(), hour: "" + d.getHours(), dayOfWeek: [], dayOfMonth: []};
    } else if (input !== null && weeklyRegex.test(input)) {
      const result = weeklyRegex.exec(input);
      const d = this.getDate(result);
      rt = {type: 'WEEKLY', minute: "" + d.getMinutes(), hour: "" + d.getHours(), dayOfWeek: result[3].split(','), dayOfMonth: []};
    } else if (input !== null && monthlyRegex.test(input)) {
      const result = monthlyRegex.exec(input);
      const d = this.getDate(result);
      rt = {type: 'MONTHLY', minute: "" + d.getMinutes(), hour: "" + d.getHours(), dayOfWeek: [], dayOfMonth: result[3].split(',')};
    } else {
      rt = {type: 'OCCASIONALLY', minute: null, hour: null, dayOfWeek: [], dayOfMonth: []};
    }
    return rt;
  }

  private getDate(arr: string[]): Date {
    const n = new Date();
    n.setUTCHours(+arr[2]);
    n.setUTCMinutes(+arr[1]);
    return n;
  }
}