import { RouteVehicleDTO } from './RouteVehicleDTO';
import { ScheduledRouteLocationDTO } from './ScheduledRouteLocationDTO';

export class ScheduleDTO {
    public vehicle: RouteVehicleDTO;
    public color = '#ffffff';
    public shouldRemove: boolean;
    
    constructor(
        public id: number,
        public routeTemplateId: number,
        public code: string,
        public date: Date,
        public isRoute: boolean,
        public locations: ScheduledRouteLocationDTO[]
    ) {}
}
