import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CRUDGenericService } from 'attcei-bo-fw';
import { CronService } from './cron.service';

@Component({
  selector: 'app-route-template-schedule',
  templateUrl: './route-template-schedule.component.html',
  styleUrls: ['./route-template-schedule.component.scss']
})
export class RouteTemplateScheduleComponent implements OnInit {


  @Input() private templateId: number;

  public schedules: any[] = [];

  public vehicles: any[] = [];

  public panelOpenState: boolean[] = [];

  constructor(private service: CRUDGenericService,
    private cron: CronService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.service.getDataItem('routes/schedules', true, this.templateId)
      .subscribe(i => {
        this.schedules = i.schedules;
        this.schedules.forEach(i => this.panelOpenState.push(false));
      });

    this.service.getDataList('vehicle', true).subscribe(v => {
        this.vehicles = v.contents;
      });
  }

  add() {
    this.schedules.push({});
    this.panelOpenState.push(false);
  }

  save() {
    this.service.putData('routes/schedules', this.templateId, {schedules: this.schedules}, true)
    .subscribe(ok => {
      this.snackBar.open('Agendamento guardado', '', { duration: 2000 })
    }, nok => {
      this.snackBar.open('Ocurreu um erro ao guardar os dados', '', { duration: 2000 })
    })
  }

  remove(index: number) {
    this.schedules.splice(index, 1);
    this.panelOpenState.splice(index, 1);
  }

  isCronValid(index: number) {
    var valid = true;
    const parsed = this.cron.getExpression(this.schedules[index].schedule);
    switch (parsed.type) {
        case 'DAILY':
          valid = valid && (parsed.hour != null) && (parsed.minute != null);
          break;
        case 'WEEKLY':
          valid = valid && (parsed.hour != null) && (parsed.minute != null) && (parsed.dayOfWeek != null) && (parsed.dayOfWeek.length > 0);
          break;
        case 'MONTHLY':
          valid = valid && (parsed.hour != null) && (parsed.minute != null) && (parsed.dayOfMonth != null) && (parsed.dayOfMonth.length > 0);
          break;
        default:
          valid = false;
    }

    return valid;
  }

  isVehicleValid(index: number) {
    return !!this.schedules[index].vehicleId;
  }

  isValid(index: number) {
    return this.isVehicleValid(index) && this.isCronValid(index);
  }

  isAllValid() {
    var valid = true;
    for (var i = 0; i < this.schedules.length; i++) {
      valid = valid && this.isValid(i);
    }
    return valid;
  }


}
