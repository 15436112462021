import { Component, OnInit } from '@angular/core';
import { RouteScreenHandler } from 'src/app/handlers/RouteScreenHandler';
import screen from '../../../../assets/RouteScreen.json';


@Component({
  selector: 'app-route-list',
  templateUrl: './route-list.component.html',
  styleUrls: ['./route-list.component.scss']
})

export class RouteListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(private screenHandler: RouteScreenHandler) { }

  ngOnInit() {
  }

}