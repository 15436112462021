import { Observable } from 'rxjs';
import { AbstractScreenHandler, CRUDGenericService, TypeValidatorService } from 'attcei-bo-fw';
import { ScreenHandler } from 'attcei-bo-fw/lib/screen/interfaces/ScreenHandler';
import { Injectable } from '@angular/core';
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { Sort } from '@angular/material/sort';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';
import { BasicParishDTO } from '../classes/dto/BasicParishDTO';
import { ParishDTO } from '../classes/dto/ParishDTO';

@Injectable({
    providedIn:'root'
})
export class ParishScreenHandler extends AbstractScreenHandler<BasicParishDTO, ParishDTO> implements ScreenHandler<BasicParishDTO, ParishDTO>{
    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }
    
    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<ParishDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }

    post(item: BasicParishDTO): Observable<ParishDTO> {
        console.log('Recebi no post -> ', item);
        var dto: BasicParishDTO = super.parse(item);
        var res: Observable<ParishDTO> = this.service.postData(this.getURL(),dto,true);
        console.log('result -> ', res);
        return res;
    }

    update(item: ParishDTO):Observable<ParishDTO> {
        console.log('Recebi no update -> ', item);
        var dto: ParishDTO = super.parse(item);
        var res: Observable<ParishDTO> = this.service.putData(this.getURL(), dto.id, dto, true);
        console.log('parsed -> ', res);
        return res;
    }

    delete(item: any):Observable<ParishDTO> {
        return this.service.delete(this.getURL(), item.id, true);
    }

    getItem(id: number):Observable<ParishDTO> {
        return this.service.getDataItem(this.getURL(), true, id);
    }

    getURL(): string {
        return 'parish';
    }

    getId(item: ParishDTO): number {
        return item.id;
    }

    protected getUpdateTypeName(): string {
        return 'ParishDTO';
    }

    protected getCreateTypeName(): string {
        return 'BasicParishDTO';
    }
}