import { RouteLocationCompositionDTO } from './RouteLocationCompositionDTO';

export class TypedRouteLocationDTO {
    constructor(
        public id: number,
        public code: string,
        public address: string,
        public latitude: number,
        public longitude: number,
        public type: string,
        public containers: number,
        public totalCapacity: number,
        public lastCollectionDate: number,
        public lastMeasurementDate: number,
        public lastMeasuredValue: number,
        public alpha: number,
        public routes: number[]
    ) {}
}
