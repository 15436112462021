import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Observable, Subject } from 'rxjs';
import { LocationDTO } from '../route-template-edit/LocationDTO';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-route-template-edit-map',
  templateUrl: './route-template-edit-map.component.html',
  styleUrls: ['./route-template-edit-map.component.scss']
})
export class RouteTemplateEditMapComponent implements OnInit, OnChanges  {

  @Input() private templateId: number;

  @Input() locations = new Observable<LocationDTO[]>();

  @Input() inRoute: number[];

  @Output() inRouteChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output() doSave: EventEmitter<any> = new EventEmitter<any>();

  private composition = new BehaviorSubject<Set<number>>(new Set<number>());

  private map = new Subject<google.maps.Map>();

  private markerCache: any = {};

  public polypoints: google.maps.LatLng[] = [];

  public polygon: google.maps.Polygon;

  constructor(private zone: NgZone) { }

  arraysEqual(a, b) {
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    a = a.sort();
    b = b.sort();
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.arraysEqual([...this.composition.value.values()], this.inRoute)) {
      this.composition.next(new Set<number>(this.inRoute));
    }
  }

  setMap(currentMap: google.maps.Map) {
    currentMap.setOptions({mapId: environment.mapId});
    this.map.next(currentMap);
  }

  save() {
    this.doSave.emit({});
  }


  getPointsInArea(): number[] {
    var ecos = [];

    for (const id in this.markerCache) {
      const pos = this.markerCache[id].position;
      if (google.maps.geometry.poly.containsLocation(pos, this.polygon)) {
          ecos.push(Number.parseInt(id));
      }
    }
    
    return ecos;
  }

  addSelection() {    
    const s = this.composition.value;
    this.getPointsInArea().forEach(id => s.add(id));
    this.composition.next(s);
    this.clearSelection();
  }

  removeSelection() {
    const s = this.composition.value;
    this.getPointsInArea().forEach(id => s.delete(id));
    this.composition.next(s);
    this.clearSelection();
  }

  clearSelection() {
    this.polypoints.length = 0;
    this.polygon.setPath(this.polypoints);
  }

  ngOnInit() {
    this.composition.next(new Set<number>(this.inRoute));

    this.composition.subscribe(c => {
      const newArr = [...c.values()];
      this.inRouteChange.emit(newArr);
    })

    //fit all points in the map
    combineLatest([this.locations, this.map])
      .subscribe(arr => {
        if (!arr[1] || !arr[0]) {
          return null;
        }

        const m = arr[1];
        const bounds = new google.maps.LatLngBounds();
        const locals = arr[0].filter(l => (!!l.latitude) && (!!l.longitude));

        locals.forEach(l => bounds.extend({lat: l.latitude, lng: l.longitude}));
        
        if (locals.length === 1) {
          //case there is only one location
          var offset = 0.002;     
          var center = bounds.getCenter();                            
          bounds.extend(new google.maps.LatLng(center.lat() + offset, center.lng() + offset));
          bounds.extend(new google.maps.LatLng(center.lat() - offset, center.lng() - offset));
        }
        
        m.fitBounds(bounds, 0);
        m.panToBounds(bounds);


        m.addListener('click', () => {
          this.zone.run(() => {
            console.log('clicking map')
          });
        });

        //polygon logic
        const markers = this.markerCache;

        this.polygon = new google.maps.Polygon({
            paths: this.polypoints,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            editable: true
        });

        m.addListener('click', (e) => {
          this.zone.run(() => {
            this.polypoints.push(e.latLng);
            this.polygon.setPath(this.polypoints);
          });
        });

        m.addListener('rightclick', (e) => {
          this.zone.run(() => this.clearSelection());
        });

        this.polygon.setMap(m);
      });

    combineLatest([this.locations, this.map, this.composition])
      .subscribe(arr => {
        console.log(this.inRoute, arr[2]);

        if (!arr[1] || !arr[0]) {
          return null;
        }

        const c = arr[2];
        const m = arr[1];
        const locations = arr[0];

        locations.forEach(location => {
          if ((!this.markerCache[location.id])) {
            const newMarker = new google.maps.marker.AdvancedMarkerElement({
              position: {lat: location.latitude, lng: location.longitude},
              title: location.code
            });
  
            newMarker.addListener('click', () => {
              this.zone.run(() => {
                const s = this.composition.value;
                if (s.has(location.id)) {
                  s.delete(location.id);
                } else {
                  s.add(location.id);
                }
                this.composition.next(s);
              });
            });
    
            this.markerCache[location.id] = newMarker;
          }

          const icon = c.has(location.id) ? new google.maps.marker.PinElement({
            background: '#00FF00',
            borderColor: '#1e2e4a',
            glyphColor: '#1e2e4a',
          }) : new google.maps.marker.PinElement({
            background: '#EEEEEE',
            borderColor: '#1e2e4a',
            glyphColor: '#1e2e4a',
          });

          const marker = this.markerCache[location.id];
          if (!marker.map) {
            marker.content = icon.element;
            marker.map = m;
          } if (marker.content !== icon.element) {
            marker.content = icon.element;
          }
        });
      });
  
  }
  

}
