import { Component, OnInit, Input, EventEmitter, Output, NgZone } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, from } from 'rxjs';
import { TypedRouteLocationDTO } from '../dto/TypedRouteLocationDTO';
import { ScheduleDTO } from '../dto/ScheduleDTO';
import { PredictionService } from '../prediction.service';
import { MapLocationDTO } from './dto/MapLocationDTO';
import { map, pairwise, flatMap, filter } from 'rxjs/operators';
import {} from 'googlemaps';
import { ScheduledLocationDTO } from '../dto/ScheduledLocationDTO';
import { ScheduledRouteLocationDTO } from '../dto/ScheduledRouteLocationDTO';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map-builder',
  templateUrl: './map-builder.component.html',
  styleUrls: ['./map-builder.component.scss']
})
export class MapBuilderComponent implements OnInit {

  @Input() private date: BehaviorSubject<Date>;

  @Input() private min: BehaviorSubject<number>;

  @Input() private locations: BehaviorSubject<TypedRouteLocationDTO[]>;

  @Input() private map = new Subject<google.maps.Map>();

  @Input() private selected = new Subject<Boolean>();

  @Input() private scheduledLocations: BehaviorSubject<Map<string, ScheduledLocationDTO>>;

  @Output() private scheduleEvents = new EventEmitter<ScheduleDTO>();

  private markerCache: any = {};

  constructor(private predictionService: PredictionService, private zone: NgZone) { }

  ngOnInit() {
    combineLatest([this.locations, this.map, this.selected])
      .subscribe(arr => {
        if (!arr[1] || !arr[0] || !arr[2]) {
          return null;
        }
        const m = arr[1];
        const bounds = new google.maps.LatLngBounds();
        arr[0]
          .filter(l => (!!l.latitude) && (!!l.longitude))
          .forEach(l => bounds.extend({lat: l.latitude, lng: l.longitude}));
        
        //case there is only one location
        var offset = 0.002;     
        var center = bounds.getCenter();                            
        bounds.extend(new google.maps.LatLng(center.lat() + offset, center.lng() + offset));
        bounds.extend(new google.maps.LatLng(center.lat() - offset, center.lng() - offset));

        
        m.fitBounds(bounds, 0);
        m.panToBounds(bounds);
      });


    combineLatest([this.locations, this.date, this.scheduledLocations, this.map, this.min])
    .pipe(
      map(arr => arr[0].map((l) => this.toMapLocation(l, arr[1], arr[2], arr[3])).filter(l => l.prediction > (arr[4] / 100.0))),
      map(arr => this.toObjectCache(arr)),
      pairwise(),
      map(arr => this.diff(arr[0], arr[1])),
      flatMap(arr => from(arr)),
      filter(location => (!!location.latitude) && (!!location.longitude))
    )
    .subscribe(location => {
      if ((!this.markerCache[location.uniquecode])) {
        const newMarker = new google.maps.marker.AdvancedMarkerElement({
          position: {lat: location.latitude, lng: location.longitude},
          title: location.code
        });

        newMarker.addListener('click', () => {
          this.zone.run(() => {
            const dto = new ScheduleDTO(null, null, null, this.date.value, false, [new ScheduledRouteLocationDTO(location.source)]);
            dto.shouldRemove = !!this.scheduledLocations.value.get(location.source.type + '_' + location.source.id);
            this.scheduleEvents.emit(dto);
          });
        });

        this.markerCache[location.uniquecode] = newMarker;
      }

      const icon = new google.maps.marker.PinElement({
        background: '#' + location.color,
        borderColor: '#1e2e4a',
        glyphColor: '#1e2e4a',
        glyph: location.icon
      })

      const marker = this.markerCache[location.uniquecode];
      if (location.color && !marker.map) {
        marker.content = icon.element;
        marker.map = location.map;
      } else if (!location.color && marker.map) {
        marker.map = null;
      } else {
        marker.content = icon.element;
      }
    });

  }

  setMap(currentMap: google.maps.Map) {
    currentMap.setOptions({mapId: environment.mapId});
    this.map.next(currentMap);
  }

  diff(a: any, b: any): MapLocationDTO[] {
    const diff: MapLocationDTO[] = [];

    const ak = Object.keys(a);
    const bk = Object.keys(b);
    const allkeys = ak.concat(bk.filter(item => {
        return ak.indexOf(item) < 0;
    }));

    allkeys.forEach(k => {
      if ((a[k] ? a[k].color + a[k].icon : null) !== (b[k] ? b[k].color + b[k].icon  : null)) {
        if (b[k]) {
          diff.push(b[k]);
        } else {
          a[k].color = null;
          diff.push(a[k]);
        }
      }
    });

    return diff;
  }

  toMapLocation(
    location: TypedRouteLocationDTO,
    date: Date,
    scheduled: Map<string, ScheduledLocationDTO>,
    currentMap: any): MapLocationDTO {

    let color: string;
    let icon: string;
    let pred: number;

    const sch = scheduled.get(location.type + '_' + location.id);
    if (sch && sch.schedule.color) {
      color = sch.schedule.color.substr(1).toUpperCase();
      icon = '' + (sch.schedule.locations.findIndex(sl => sl.source.id === location.id) + 1);
      pred = Number.MAX_VALUE;
    } else {
      pred = this.predictionService.predict(location, date, null);
      color = this.getColor(pred);
      icon = '•';
    }

    return new MapLocationDTO(
        location,
        location.type + '_' + location.id,
        location.code,
        color,
        location.latitude,
        location.longitude,
        icon,
        currentMap,
        pred
    );
  }

  getColor(prediction: number) {
    if (prediction < 0.25 ) {
      return '00FF00';
    } else if (prediction < 0.5 ) {
      return '32CD32';
    } else if (prediction < 0.625 ) {
      return 'FFA500';
    } else if (prediction < 0.9 ) {
      return 'FF8C00';
    } else {
      return 'FF0000';
    }
  }

  toObjectCache(locations: MapLocationDTO[]): any {
    const cache = {};
    locations.forEach(l => cache[l.uniquecode] = l);
    return cache;
  }

}
