import { Component, OnInit } from '@angular/core';
import { RouteTemplateScreenHandler } from 'src/app/handlers/RouteTemplateScreenHandler';
import screen from '../../../../assets/RouteTemplateScreen.json'


@Component({
  selector: 'app-route-template-list',
  templateUrl: './route-template-list.component.html',
  styleUrls: ['./route-template-list.component.scss']
})
export class RouteTemplateListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(public screenHandler: RouteTemplateScreenHandler) { }

  ngOnInit() {
  }

}
