import { Injectable } from '@angular/core';

import { CRUDGenericService } from 'attcei-bo-fw';
import { Observable, of } from 'rxjs';
import { RouteDTO } from './tabular-builder/dto/RouteDTO';
import { PaginatedDTO } from './dto/PaginatedDTO';
import { ScheduleDTO } from './dto/ScheduleDTO';
import { BaseRouteDTO } from './tabular-builder/dto/BaseRouteDTO';
import { BaseScheduleDTO } from './dto/BaseScheduleDTO';
import { map, toArray, catchError } from 'rxjs/operators';

@Injectable()
export class RoutesService {

    constructor(private service: CRUDGenericService) { }

    get(): Observable<PaginatedDTO<BaseRouteDTO>> {
        return this.service.getDataItem('builder/routes/template', true);
    }

    updateTemplate(schedule: ScheduleDTO) {
        return this.service.putData('routes/template', schedule.routeTemplateId, {
            id: schedule.routeTemplateId,
            code: schedule.code,
            locationId: schedule.locations.map(l => l.source.id)
        }, true);
    }

    saveTemplate(schedule: ScheduleDTO) {
        return this.service.postData('routes/template', {
            code: schedule.code,
            locationId: schedule.locations.map(l => l.source.id)
        }, true);
    }

    updateSchedule(schedule: ScheduleDTO) {
        return this.service.putData('builder/route', schedule.id, this.clean({
            id: schedule.id,
            name: schedule.code,
            scheduledAt: [
                schedule.date.getFullYear(),
                schedule.date.getMonth() + 1,
                schedule.date.getDate(),
                schedule.date.getHours(),
                schedule.date.getMinutes()],
            vehicleId: schedule.vehicle.id,
            routeTemplateId: schedule.routeTemplateId,
            material: 'RUB',
            locations: schedule.locations.map(l => {
                return {
                    id: l.source.id,
                    prediction: l.prediction
                };
            })
        }), true);
    }

    deleteSchedule(schedule: ScheduleDTO) {
        return this.service.delete('builder/route', schedule.id, true);
    }

    getSchedule(): Observable<BaseScheduleDTO[]> {
        return this.service.getDataItem('builder/route?filter={"status":"PENDING"}', true)
            .pipe(
                map(s => s.contents),
                map(arr => arr.map(s => {
                    const dt = new Date();
                    dt.setFullYear(s.scheduledAt.date.year);
                    dt.setMonth(s.scheduledAt.date.month - 1);
                    dt.setDate(s.scheduledAt.date.day);
                    dt.setHours(s.scheduledAt.time.hour);
                    dt.setMinutes(0);
                    dt.setSeconds(0);

                    return new BaseScheduleDTO(
                        s.id,
                        null,
                        s.vehicleId,
                        s.name,
                        dt,
                        s.waypoints.map(w => w.locationId)
                    );
                }))
            );
    }

    getDensity(material: string): Observable<number> {
        return this.service.getDataItem('material?filter={"code":"' +material+ '"}', true)
            .pipe(
                map(s => s.contents),
                map(arr => arr[0].trailerDensity),
                catchError(e => of(NaN))
                );
    }

    saveNewSchedule(schedule: ScheduleDTO) {
        return this.service.postData('route', this.clean({
            name: schedule.code,
            scheduledAt: [
                schedule.date.getFullYear(),
                schedule.date.getMonth() + 1,
                schedule.date.getDate(),
                12,
                0],
            vehicleId: schedule.vehicle.id,
            routeTemplateId: schedule.routeTemplateId,
            material: 'RUB',
            locations: schedule.locations.map(l => {
                return {
                    id: l.source.id,
                    prediction: l.prediction
                };
            })
        }), true);
    }

    /**
     * Cleans null/undefined properties
     * @param obj C
     */
    clean(obj: any): any {
        const propNames = Object.getOwnPropertyNames(obj);
        for (let i = 0; i < propNames.length; i++) {
          const propName = propNames[i];
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
          }
        }
        return obj;
    }

}
