import { Component, OnInit } from '@angular/core';
import { LocationScreenHandler } from 'src/app/handlers/LocationScreenHandler';
import screen from '../../../../assets/LocationScreen.json'


@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(public screenHandler: LocationScreenHandler) { }


  ngOnInit() {
  }

}
