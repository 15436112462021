import { Component, OnInit } from '@angular/core';
import { ParishScreenHandler } from 'src/app/handlers/ParishScreenHandler';
import screen from '../../../../assets/ParishScreen.json';


@Component({
  selector: 'app-parish-list',
  templateUrl: './parish-list.component.html',
  styleUrls: ['./parish-list.component.scss']
})
export class ParishListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(public screenHandler: ParishScreenHandler) { }

  ngOnInit() {
  }

}