import { Component, OnInit } from '@angular/core'
import { StaticInfoInitializer } from 'attcei-bo-fw'

@Component({
  selector: 'app-schema-validator-loader',
  templateUrl: './schema-validator-loader.component.html',
  styleUrls: ['./schema-validator-loader.component.scss']
})
export class SchemaValidatorLoaderComponent implements OnInit {

  constructor(private staticInitializer:StaticInfoInitializer) { }

  ngOnInit() {
    this.staticInitializer.loadResources()
  }

}
