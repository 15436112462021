import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionHandler } from 'attcei-bo-fw';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private sessionHandler: SessionHandler, private router: Router){}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isAllowed = false;
   
    console.log(next);

    isAllowed = this.sessionHandler.isAllowed(next) || this.isExceptionURL(next.routeConfig.path);

    
    return true;
  }

  private isExceptionURL(routerPath:string):boolean{
    return "incidents/list" == routerPath ||
            "incident/:id" == routerPath ||
            "welcome" == routerPath;
  } 
}
