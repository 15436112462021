import { Component, OnInit } from '@angular/core';
import screen from '../../../../assets/UserScreen.json'
import { ScreenDescriptor } from 'attcei-bo-fw';

@Component({
  selector: 'gior-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class GiorListComponent implements OnInit {

  public screenDescriptor:ScreenDescriptor = JSON.parse(JSON.stringify(screen));

  constructor() { }

  ngOnInit() {
  }

}
