import { Component, OnInit } from '@angular/core';
import { IncidentScreenHandler } from 'attcei-bo-fw';
import screen from '../../../../assets/IncidentScreen.json';

@Component({
  selector: 'app-incident-list',
  templateUrl: './incident-list.component.html',
  styleUrls: ['./incident-list.component.scss']
})

export class IncidentListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(public screenHandler: IncidentScreenHandler) { }

  ngOnInit() {
  }

}
