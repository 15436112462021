import { Component, OnInit, Input } from '@angular/core';
import { RouteDTO } from 'src/app/classes/dto/RouteDTO';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm, ValidationErrors, AbstractControl } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { CRUDGenericService, DateParser } from 'attcei-bo-fw';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid );
    const invalidParent = !!(control && control.parent && control.parent.invalid);
    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-route-completed-editor',
  templateUrl: './route-completed-editor.component.html',
  styleUrls: ['./route-completed-editor.component.scss']
})
export class RouteCompletedEditorComponent implements OnInit {

  @Input() route: RouteDTO;
  
  public routeForm: FormGroup;

  public dates: FormGroup;

  public kilometers: FormGroup;

  public datesMather = new MyErrorStateMatcher();

  public kilometersMatcher = new MyErrorStateMatcher();

  public startedAtTime = '';

  public endedAtTime = '';

  public loading = false;

  constructor(
    private crud: CRUDGenericService, 
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    const startDate = this.route.startedAt;
    const endDate = this.route.endedAt;

    this.startedAtTime = this.toTime(startDate);
    this.endedAtTime = this.toTime(endDate);

console.log( this.startedAtTime, this.endedAtTime);

    this.dates =  new FormGroup({
      startedAt: new FormControl(this.toDate(startDate), [ Validators.required ]),
      startedAtTime: new FormControl(this.startedAtTime, [Validators.required]),
      endedAt: new FormControl(this.toDate(endDate), [Validators.required]),
      endedAtTime: new FormControl(this.endedAtTime, [Validators.required])
    }, [this.checkDates, this.checkTimes]);

    this.kilometers = new FormGroup({
      startingKilometers: new FormControl(this.route.startingKilometers, [ Validators.required, Validators.min(0) ]),
      endingKilometers: new FormControl(this.route.endingKilometers, [Validators.required, Validators.min(0)])
    }, this.checkKilometers );

    this.routeForm = new FormGroup({
      dates: this.dates,      
      kilometers: this.kilometers,
      collectedWeight: new FormControl(this.route.collectedWeight, [ Validators.required, Validators.min(0) ]),
    });
  }

  toDate(obj: any): Date {
    if (obj == null) {
      return null;
    } 
    return DateParser.toDate(obj);
  }

  toTime(obj: any): string {
    if (obj == null) {
      return '';
    } 
    
    return this.toTimeString(DateParser.toDate(obj));
  }

  toTimeString(dt: Date): string {
    return dt ? (this.pad(dt.getHours(),2) + ":" + this.pad(dt.getMinutes(),2)) : '';
  }
  

  pad(num, size) {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  updateRoute() {
    console.log("updateRoute", this.routeForm.valid, this.routeForm.value);
    if (this.routeForm.valid && !this.loading) {
      const value = this.routeForm.value;

      const newRoute = {
        startedAt: this.format(value.dates.startedAt, value.dates.startedAtTime),
        endedAt: this.format(value.dates.endedAt, value.dates.endedAtTime),
        startingKilometers: value.kilometers.startingKilometers,
        endingKilometers: value.kilometers.endingKilometers,
        collectedWeight: value.collectedWeight
      };

      console.log(newRoute);

      this.loading = true;

      this.crud.putData('route/simple', this.route.id, newRoute, true).subscribe(r => {
        this.loading = false;
        this.snackBar.open('Alteração efectuada', '', { duration: 2000 });
        location.reload();
      }, error => {
        this.loading = false;
        this.snackBar.open('Ocurreu um erro a alterar alterar o histórico, por favor tente mais tarde', '', { duration: 2000 });
      })
    }
  }

  private format(date: string, time: string): string {
    const arr = time.split(":");
    const tmp = new Date(date);
    const dt = new Date(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), +arr[0], +arr[1], 0);

    const rt = this.pad(dt.getUTCFullYear(),4) + "-" + this.pad(dt.getUTCMonth() + 1,2) + "-" + this.pad(dt.getUTCDate(),2) + "T" + 
      this.pad(dt.getUTCHours(),2) + ":" + this.pad(dt.getUTCMinutes(),2) + ":" + this.pad(dt.getUTCSeconds(),2);

      return rt;
  }

  static addError(control: AbstractControl, error: ValidationErrors | null): ValidationErrors | null {
    if (!control.errors) {
      control.setErrors(error);
      return error;
    } else if (!error) {
      return control.errors;
    }

    const newError = {...control.errors, ...error};
    control.setErrors(newError);
    return newError;
  }
  
  checkDates(group: FormGroup) {
    var error = null;

    if (group.get('startedAt').value && group.get('endedAt').value) {
      const startedDate = new Date(group.get('startedAt').value);
      startedDate.setHours(0,0,0,0);
  
      const endedDate = new Date(group.get('endedAt').value);
      endedDate.setHours(0,0,0,0);
      
      const startedAt = startedDate.getTime();
      const endedAt = endedDate.getTime();
      error = startedAt <= endedAt ? null : { wrongDates: true };
    }
    
    return RouteCompletedEditorComponent.addError(group.get('endedAt'), error);
  }
  
  checkTimes(group: FormGroup) {
    var error = null;

    const startedAt = group.get('startedAt').value;
    const endedAt = group.get('endedAt').value;
    const startedAtTime = group.get('startedAtTime').value;
    const endedAtTime = group.get('endedAtTime').value;

    if (startedAt && endedAt && startedAtTime && endedAtTime && (endedAt.getTime() == startedAt.getTime())) {
      const startedAtTimeObj = new Date('1970-01-01T' + startedAtTime + ':00Z').getTime();
      const endedAtTimeObj = new Date('1970-01-01T' + endedAtTime + ':00Z').getTime();
      error = startedAtTimeObj < endedAtTimeObj ? null : { wrongTimes: true };
    }

    return RouteCompletedEditorComponent.addError(group.get('endedAtTime'), error);
  }
  
  checkKilometers(group: FormGroup) {
    const startingKilometers = group.get('startingKilometers').value;
    const endingKilometers = group.get('endingKilometers').value;
    const error = startingKilometers < endingKilometers ? null : { wrongKilometers: true };
    return RouteCompletedEditorComponent.addError(group.get('endingKilometers'), error);
  }

}
