import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { RouteDTO } from 'src/app/classes/dto/RouteDTO';
import { LocationDTO } from 'src/app/classes/dto/LocationDTO';
import { CRUDGenericService } from 'attcei-bo-fw';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { TableLocationWaypoint } from 'src/app/classes/TableLocationWaypoint';
import { RouteUtils } from 'src/app/classes/RouteUtils';
import { RouteStatus } from 'src/app/classes/dto/RouteStatus';
import { WaypointStatus } from 'src/app/classes/dto/WaypointStatus';
import { BuilderDialogComponent } from '../route-builder/builder-dialog/builder-dialog.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-route-completed-table',
  templateUrl: './route-completed-table.component.html',
  styleUrls: ['./route-completed-table.component.scss']
})
export class RouteCompletedTableComponent implements OnInit, OnChanges {
  
  private translations = {"HIGH": "Alta","MEDIUM": "Média","LOW": "Baixa","NONE": "Nenhum"};

  @Input() route: RouteDTO;
  @Input() locations: LocationDTO[];

  @ViewChild('paginator',{static: false}) paginator: MatPaginator;

  public dataSource: MatTableDataSource<TableLocationWaypoint> = new MatTableDataSource<TableLocationWaypoint>();
  public data: Array<TableLocationWaypoint> = new Array<TableLocationWaypoint>();

  public columns = ['code','status','estimatedFilling','measuredFilling','measuredContamination','collectedAt','observation'];

  constructor(
    private service: CRUDGenericService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.route != null && this.locations != null) {
      this.data = RouteUtils.orderData(this.route, this.locations);
      this.dataSource.data = this.data;
      this.dataSource.paginator = this.paginator;
    }
  }


  doCancel() {
    this.openGenericDialog(
      'Cancelar Agendamento',
      'Deseja cancelar o agendamento?',
      'Sim', 'Não')
      .subscribe(result => {
        if (result === 'Sim') {
          this.service.delete('builder/route', this.route.id, true).subscribe(r => {
            this.refresh();
          }, error => {
            this.snackBar.open('Ocurreu um erro, por favor tente mais tarde', '', { duration: 2000 });
          });
        }
      });
  }

  refresh() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['/route/' + this.route.id]));
  }

  getRouteStatus(state:string):string{
    return RouteStatus[state];
  }

  getContaminationTranslation(name:string):string{
    return this.translations[name];
  }

  getWaypointStatus(state:string):string{
      return WaypointStatus[state];
  }
  
  ngOnInit() {
  }

  openGenericDialog(title: string, message: string, ...btns: string[]): Observable<any> {
    const config = new MatDialogConfig<any>();
    config.data = {title, message, btns};
    const dialogRef = this.dialog.open(BuilderDialogComponent, config);
    return dialogRef.afterClosed();
  }
  
}
