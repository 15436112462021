import { Component, OnInit } from '@angular/core';
import { SessionHandler, CRUDGenericService } from 'attcei-bo-fw';
import { Subject } from 'rxjs';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { GiorDataSource } from '../route/route-builder/dto/GiorDataSource';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  
  public username: string;

  public weightData: ChartDataSets[] = [];

  public weightLabels: Label[] = [];

  public durationData: ChartDataSets[] = [];

  public durationLabels: Label[] = [];

  public displayedColumns: string[] = ['name', 'scheduledAt'];

  public environment = environment;

  public chartOptions = {
      scales : {
          yAxes : [{
              ticks : {
                  beginAtZero : true
              }
          }]
      }
  };

  constructor(private service: CRUDGenericService) { }

  ngOnInit() {
    const session = SessionHandler.getSession();
    if (session) {
      this.username = session.user.name;
    }

    const sub = new Subject<Array<RouteStatisticsDTO>>();
    this.service.getDataItem('statistics/dashboard', true)
      .pipe(catchError(err => []))
      .subscribe(sub);

    sub.subscribe(a => this.getData(a,
      v => v.avgCollectedWeight,
      v => this.weightLabels = v,
      v => this.weightData = v));

    sub.subscribe(a => this.getData(a,
      v => v.avgDuration,
      v => this.durationLabels = v,
      v => this.durationData = v));
  }

  public getDate(v: any): string {
    let month = '' + ( v.date.month + 1);
    let day = '' +  v.date.day;
    const year =  v.date.year;

    if (month.length < 2) {
        month = '0' + month;
    }

    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  private getData(
    a: RouteStatisticsDTO[],
    getter: (v: RouteStatisticsDTO) => number,
    setLbl: (v: any[]) => void,
    setDta: (v: any[]) => void) {

    setDta([]);

    const datas = new Set<number>();
    const dta = new Map<string, Map<number, number>>();
    a.forEach(v => {
      if (!dta.get(v.code)) {
        dta.set(v.code, new Map<number, number>());
      }
      datas.add(v.date);
      dta.get(v.code).set(v.date, getter(v));
    });

    const ordered = Array.from(datas).sort();
    setLbl(ordered.map(d => new Date(d).toLocaleString('pt-pt', { month: 'long' })));
    setDta(Array.from(dta.keys()).map(lbl => {
      return {data: ordered.map(o => dta.get(lbl).get(o)), label: lbl};
    }));
  }
}
