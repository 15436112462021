import { Component, OnInit } from '@angular/core';
import { VehicleScreenHandler } from '../../../handlers/VehicleScreenHandler'
import screen from '../../../../assets/VehicleScreen.json'

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(public screenHandler: VehicleScreenHandler) { }

  ngOnInit() {
  }
}