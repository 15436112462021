import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouteDetailsDTO } from './dto/RouteDetailsDTO';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PredictionService } from '../prediction.service';
import { DisplayLocationDetailDTO } from './dto/DisplayLocationDetailDTO';
import { Subject, BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { GiorDataSource } from '../dto/GiorDataSource';
import { map } from 'rxjs/operators';
import { TypedRouteLocationDTO } from '../dto/TypedRouteLocationDTO';
import { ScheduleDTO } from '../dto/ScheduleDTO';
import { ScheduledRouteLocationDTO } from '../dto/ScheduledRouteLocationDTO';

class RouteSort implements Sort {
  active: string;
  direction: SortDirection;
  constructor(active: string, direction: SortDirection) {
    this.active = active;
    this.direction = direction;
  }
}

@Component({
  selector: 'app-tabular-details',
  templateUrl: './route-details.component.html',
  styleUrls: ['./route-details.component.scss'],
  providers: [PredictionService]
})
export class RouteDetailsComponent implements OnInit {

  public displayedColumns: string[] = ['scheduleBtn', 'code', 'address', 'installedCapacity', 'nContainers',
  'lastCollection', 'lastMeasurement', 'lastValue', 'predictionT0', 'predictionT1', 'predictionT2'];

  private sort: Subject<Sort> = new BehaviorSubject<Sort>(new RouteSort('code', 'asc'));

  public dataSource = new GiorDataSource<DisplayLocationDetailDTO>();

  @Output() public scheduleEvents = new EventEmitter<ScheduleDTO>();

  constructor(private predictionService: PredictionService,
              @Inject(MAT_DIALOG_DATA) private details: RouteDetailsDTO) { }

  ngOnInit() {
    const t0 = this.details.date;
    const t1 = new Date(t0.getTime() + this.predictionService.MILIS_IN_DAY);
    const t2 = new Date(t1.getTime() + this.predictionService.MILIS_IN_DAY);

    const observable = combineLatest(of(this.details.locations), this.details.scheduled)
      .pipe(map(arr => arr[0].map(l => {
          let schDate: Date;
          const sch = arr[1].get(l.type + '_' + l.id);
          if (sch) {
            schDate = sch.schedule.date;
          }

          return new DisplayLocationDetailDTO(
            l,
            l.id,
            l.code,
            l.address,
            l.totalCapacity,
            l.containers,
            new Date(l.lastCollectionDate),
            new Date(l.lastMeasurementDate),
            l.lastMeasuredValue / 100,
            this.predictionService.predict(l, t0, schDate),
            this.predictionService.predict(l, t1, schDate),
            this.predictionService.predict(l, t2, schDate)
          );
        })));

    combineLatest([observable, this.sort])
          .pipe(map(arr => {
            const data = arr[0].slice();
            data.sort(this.dynamicSort(arr[1]));
            return data;
          })).subscribe(this.dataSource.getSubject());
  }


  private dynamicSort(sort: Sort) {
    return (a: any, b: any) => {
      let sortValue = 0;
      if (typeof a[sort.active] === 'string') {
        sortValue = a[sort.active] > b[sort.active] ? 1 : -1;
      } else {
        sortValue = a[sort.active] - b[sort.active];
      }

      return sort.direction === 'asc' ? sortValue : -1 * sortValue;
    };
  }

  onSortData(sort: Sort) {
    this.sort.next(sort);
  }

  onSchedule(location: TypedRouteLocationDTO) {
    this.scheduleEvents.emit(
      new ScheduleDTO(null, null, null, this.details.date, false, [new ScheduledRouteLocationDTO(location)])
    );
  }


  getColor(prediction: number) {
    if (prediction < 0.25 ) {
      return 'lime';
    } else if (prediction < 0.5 ) {
      return 'limegreen';
    } else if (prediction < 0.625 ) {
      return 'orange';
    } else if (prediction < 0.9 ) {
      return 'darkorange';
    } else {
      return 'red';
    }
  }

}
