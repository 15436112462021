import { Component, OnInit } from '@angular/core';
import { ContainerTypeScreenHandler } from 'src/app/handlers/ContainerTypeScreenHandler';
import screen from '../../../../assets/ContainerTypeScreen.json'


@Component({
  selector: 'app-container-type-list',
  templateUrl: './container-type-list.component.html',
  styleUrls: ['./container-type-list.component.scss']
})
export class ContainerTypeListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(public screenHandler: ContainerTypeScreenHandler) { }

  ngOnInit() {
  }

}
