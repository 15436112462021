import { LatLng, Polyline } from '@agm/core';
import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Coordinate, CRUDGenericService } from 'attcei-bo-fw';
import { BehaviorSubject, combineLatest, from, Observable, Subject } from 'rxjs';
import { filter, flatMap, map, pairwise, toArray } from 'rxjs/operators';
import { BuilderDialogComponent } from '../../route/route-builder/builder-dialog/builder-dialog.component';
import { RouteLocationDTO } from '../../route/route-builder/dto/RouteLocationDTO';
import { ScheduledRouteLocationDTO } from '../../route/route-builder/dto/ScheduledRouteLocationDTO';
import { ScheduleDTO } from '../../route/route-builder/dto/ScheduleDTO';
import { LocationsService } from '../../route/route-builder/locations.service';
import { LocationDTO } from '../route-template-edit/LocationDTO';

@Component({
  selector: 'app-route-template-edit-route',
  templateUrl: './route-template-edit-route.component.html',
  styleUrls: ['./route-template-edit-route.component.scss']
})
export class RouteTemplateEditRouteComponent implements OnInit  {

  public polypoints: google.maps.LatLng[] = [];

  private map = new Subject<google.maps.Map>();

  private route: Observable<google.maps.LatLng[]>;

  private polyline: google.maps.Polyline;

  @Input() templateId: number;

  constructor(private zone: NgZone, private crud: CRUDGenericService, private snackBar: MatSnackBar,
    private dialog: MatDialog) { }


  setMap(currentMap: google.maps.Map) {
    this.map.next(currentMap);
  }

  openGenericDialog(title: string, message: string, ...btns: string[]): Observable<any> {
    const config = new MatDialogConfig<any>();
    config.data = {title, message, btns};
    const dialogRef = this.dialog.open(BuilderDialogComponent, config);
    return dialogRef.afterClosed();
  }

  save() {
    const points = this.polyline.getPath().getArray().map(p => new Coordinate(p.lat(), p.lng()));

    this.openGenericDialog(
      'Guardar rota',
      'Deseja substituir a rota pré-definida para este circuito?',
      'Cancelar', 'Guardar')
      .subscribe(result => {
        if (result === 'Guardar') {
          this.crud.putData('routes/templates/waypoint', this.templateId, points, true).subscribe(ok => {
            this.snackBar.open('Circuito actualizado', '', { duration: 2000 })
          }, nok => {
            this.snackBar.open('Ocurreu um erro ao guardar os dados', '', { duration: 5000 })
          })
        }
      });
  }

  ngOnInit() {
    this.route = this.crud.getDataItem("routes/templates/waypoint", true, this.templateId)
      .pipe(map(a => a.map(v => new google.maps.LatLng(v.latitude, v.longitude))));
       

    //fit all points in the map
    combineLatest([this.route, this.map]).pipe(filter(a => a[0] != null && a[1] != null)).subscribe(arr => {
      this.polypoints = arr[0];
      const m = arr[1];

      const lineSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      };

      if (this.polypoints.length == 0) {
        m.setZoom(7.25);
        m.panTo(new google.maps.LatLng(40.0306092,-5.9016438));
      } else {
        const bounds = new google.maps.LatLngBounds();
        this.polypoints.forEach(l => bounds.extend(l));
        m.fitBounds(bounds, 0);
        m.panToBounds(bounds);
      }


      this.polyline = new google.maps.Polyline({
          path: this.polypoints,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          editable: true,
          icons: [
            {
              icon: lineSymbol,
              offset: "100%",
              repeat: '50px'
            },
          ],
      });

      google.maps.event.addListener(this.polyline, 'edit_end', function(path){
        console.log("ended edit", path);
        this.polypoints = path;
      });
      
      m.addListener('click', (e) => {
        this.zone.run(() => {
          this.polypoints = this.polyline.getPath().getArray();
          this.polypoints.push(e.latLng);
          this.polyline.setPath(this.polypoints);
        });
      });
      
      this.polyline.setMap(m);

    });
  
  }
  

}
