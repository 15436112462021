import { Injectable } from "@angular/core";
import { CRUDGenericService } from "attcei-bo-fw";
import { UserProfileDTO } from "attcei-bo-fw/lib/classes/dto/UserProfileDTO";
import { Observable } from "rxjs";

@Injectable({
    providedIn:'root'
})
export class UserScreenHandler{

    static readonly PROFILE_URL:string = 'user/current/wprofile';

    constructor(private service:CRUDGenericService){}

    public getCurrentUserProfile():Observable<UserProfileDTO>{
        return this.service.getDataItem(UserScreenHandler.PROFILE_URL,true);
      }
}