import { Component, OnInit } from '@angular/core';
import { CRUDGenericService, ExcelService } from 'attcei-bo-fw';
import { BehaviorSubject, Subject, combineLatest, Observable, of, empty } from 'rxjs';
import { map, tap, catchError, switchMap } from 'rxjs/operators';
import { SortDirection, Sort } from '@angular/material/sort';
import { GiorDataSource } from '../../route/route-builder/dto/GiorDataSource';
import { PaginatedDTO } from '../../route/route-builder/dto/PaginatedDTO';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


class GiorSort implements Sort {
  active: string;
  direction: SortDirection;
  constructor(active: string, direction: SortDirection) {
    this.active = active;
    this.direction = direction;
  }
}

@Component({
  selector: 'app-routestats',
  templateUrl: './routestats.component.html',
  styleUrls: ['./routestats.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class RoutestatsComponent implements OnInit {
  
  public dateInput: FormControl;

  public columns: string[] = ['code', 'qrt','avgKilometers', 'avgDuration', 'tcuv', 'qrk','dpr','dr'];

  private date = new BehaviorSubject<Moment>(moment());

  public stats = new GiorDataSource<RouteStatisticsDTO>();

  private sort: Subject<Sort> = new BehaviorSubject<Sort>(new GiorSort('code', 'asc'));

  private data = new BehaviorSubject<any[]>([]);

  public isLoading = false;

  public isEmpty: boolean;

  constructor(
    private service: CRUDGenericService,
    private snackBar: MatSnackBar,
    private excel: ExcelService) {}

  ngOnInit() {
    this.dateInput = new FormControl(moment());

    this.date.subscribe(d => this.dateInput.setValue(d));

    const rawData: Observable<RouteStatisticsDTO[]> = combineLatest([this.date, this.sort]).pipe(
      tap(arr => this.isLoading = true),
      switchMap(arr => 
        this.loadData(arr[0], arr[1].active, arr[1].direction.toUpperCase())
        .pipe(
          map((x: PaginatedDTO<RouteStatisticsDTO>) => x.contents),
          catchError(e => of([])))),
      tap(x => {
        this.isEmpty = x.length === 0;
        this.isLoading = false;
      }),
    );

    rawData.subscribe(this.stats.getSubject());

    this.stats.getSubject().subscribe(r => console.log("stats",r), r => console.log("stats error",r), () => console.log("stats complete"));

    rawData.subscribe(r => console.log("raw", r));

    rawData.pipe(map(rs => rs.map((r) => {
      return {
        'Mês': this.date.value,
        'Circuito': r.code,
        'QRT [kg/Turno]': r.qrt,
        'Percurso Médio [km]': r.avgKilometers,
        'Duração Média [h]': r.avgDuration,
        'TCUV [%]': r.tcuv * 100,
        'QRK [kg/km]': r.qrk,
        'DPR [km/ton]': r.dpr,
        'DR [kg/h]': r.dr
      };
    }))).subscribe(this.data);
  }


  loadData(date: Moment, col: string, direction: string): Observable<PaginatedDTO<RouteStatisticsDTO>> {
    return this.service.getDataItem(['statistics/route',
      '?',
      'filter={"date":"' + this.formatDate(date) + '"}',
      '&',
      'pageOrderName=' + col,
      '&',
      'pageOrderDirection=' + direction].join(''), true)
      .pipe(
        catchError(error => {
          this.snackBar.open('Erro ao carregar dados', '', { duration: 2000 });
          return of({contents: []});
      }));
  }

  onSortData(sort: Sort) {
    this.sort.next(sort);
  }

  formatDate(date: Moment) {
      var d = new Date(date.toDate()),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, '01'].join('-');
  }

  formatMonth(date: Moment) {
    var d = new Date(date.toDate()),
        month = '' + (d.getMonth() + 1),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;

    return [year, month].join('-');
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.next(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedMonth.year());
    ctrlValue.month(normalizedMonth.month());
    this.date.next(ctrlValue);
    datepicker.close();
  }

  downloadXlsx(): void {
    const arr = this.data.value.map(v => {
      const v1 = {...v};
      v1['Mês'] = this.formatMonth(v1['Mês']);
      return v1;
    })

    this.excel.exportAsExcelFile(arr, 'estatisticas.xlsx');
  }

}
