import { Observable } from "rxjs";
import { AbstractScreenHandler, CRUDGenericService, TypeValidatorService } from 'attcei-bo-fw';
import { ScreenHandler } from 'attcei-bo-fw/lib/screen/interfaces/ScreenHandler';
import { Injectable } from '@angular/core';
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { Sort } from '@angular/material/sort';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';
import { RouteWaypointDTO } from '../classes/dto/RouteWaypointDTO';

@Injectable({
    providedIn:'root'
})
export class RouteWaypointScreenHandler extends AbstractScreenHandler<RouteWaypointDTO, RouteWaypointDTO> implements ScreenHandler<RouteWaypointDTO, RouteWaypointDTO>{
    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }
    
    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<RouteWaypointDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }

    post(item: RouteWaypointDTO):Observable<RouteWaypointDTO> {
        console.log("Recebi no post -> ", item);
        var dto: RouteWaypointDTO = super.parse(item);
        var res: Observable<RouteWaypointDTO> = this.service.postData(this.getURL(),dto,true);
        console.log("result -> ", res);
        return res;
    }

    update(item: RouteWaypointDTO):Observable<RouteWaypointDTO> {
        console.log("Recebi no update -> ", item);
        var dto: RouteWaypointDTO = super.parse(item);
        var res: Observable<RouteWaypointDTO> = this.service.putData(this.getURL(), 0, dto, true);
        console.log("parsed -> ", res);
        return res;
    }

    delete(item: any):Observable<RouteWaypointDTO> {
        return this.service.delete(this.getURL(), item.id, true);
    }

    getItem(id: number):Observable<RouteWaypointDTO> {
        return this.service.getDataItem(this.getURL(), true, id);
    }

    getURL(): string {
        return "routes/waypoints"
    }

    getId(item: RouteWaypointDTO): number {
        return null;
    }
    
    protected getUpdateTypeName(): string {
        return "RouteWaypointDTO";
   
    }    
    
    protected getCreateTypeName(): string {
        return "BasicRouteWaypointDTO";
    }
}