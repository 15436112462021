import { RouteDTO } from './RouteDTO';

export class DisplayRouteDTO {
    constructor(
        public id: number,
        public code: string,
        public route: RouteDTO,
        public nLocations: number,
        public nContainers: number,
        public sumExpectedCapacity: number,
        public nLocationsP0: number,
        public nLocationsP25: number,
        public nLocationsP50: number,
        public nLocationsP75: number,
        public nLocationsP100: number,
        public fvT0: number,
        public fvT1: number,
        public fvT2: number
    ) {}
}
