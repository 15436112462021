import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from 'attcei-bo-fw';
import { UserProfileComponent } from 'attcei-bo-fw';


import { AuthGuard } from './auth.guard'
import { VehicleListComponent } from './navigation-components/vehicle/vehicle-list/vehicle-list.component';
import { VehicleDetailComponent } from './navigation-components/vehicle/vehicle-detail/vehicle-detail.component';
import { TrailerListComponent } from './navigation-components/trailer/trailer-list/trailer-list.component';
import { TrailerDetailComponent } from './navigation-components/trailer/trailer-detail/trailer-detail.component';
import { LocationListComponent } from './navigation-components/location/location-list/location-list.component';
import { LocationDetailComponent } from './navigation-components/location/location-detail/location-detail.component';
import { ContainerListComponent } from './navigation-components/container/container-list/container-list.component';
import { ContainerDetailComponent } from './navigation-components/container/container-detail/container-detail.component';
import { RouteListComponent } from './navigation-components/route/route-list/route-list.component';
import { RouteBuilderComponent } from './navigation-components/route/route-builder/route-builder.component';
import { GiorListComponent } from './navigation-components/users/users-list/users-list.component';
import { UserDetailComponent } from './navigation-components/users/users-detail/users-detail.component';
import { IncidentListComponent } from './navigation-components/incident/incident-list/incident-list.component';
import { IncidentDetailComponent } from './navigation-components/incident/incident-detail/incident-detail.component';
import { RouteCompletedDetailComponent } from './navigation-components/route/route-completed-detail/route-completed-detail.component';
import { WelcomeComponent } from './navigation-components/welcome/welcome.component';
import { RoutestatsComponent } from './navigation-components/statistics/routestats/routestats.component';
import { StatisticsReportsComponent } from './navigation-components/statistics/reports/reports.component';
import { UsersCreateComponent } from './navigation-components/users/users-create/users-create.component';
import { ContainerTypeListComponent } from './navigation-components/container-type/container-type-list/container-type-list.component';
import { ContainerTypeDetailComponent } from './navigation-components/container-type/container-type-detail/container-type-detail.component';
import { MessageListComponent } from './navigation-components/message/message-list/message-list.component';
import { MessageDetailComponent } from './navigation-components/message/message-detail/message-detail.component';
import { RouteTemplateDetailComponent } from './navigation-components/route-template/route-template-detail/route-template-detail.component';
import { RouteTemplateListComponent } from './navigation-components/route-template/route-template-list/route-template-list.component';
import { LocalityDetailComponent } from './navigation-components/locality/locality-detail/locality-detail.component';
import { LocalityListComponent } from './navigation-components/locality/locality-list/locality-list.component';
import { CodeGeneratorDetailComponent } from './navigation-components/codegenerator/codegenerator-detail/codegenerator-detail.component';
import { CodeGeneratorListComponent } from './navigation-components/codegenerator/codegenerator-list/codegenerator-list.component';
import { RouteTemplateEditComponent } from './navigation-components/route-template/route-template-edit/route-template-edit.component';
import { ContainerUploadComponent } from './navigation-components/container/container-upload/container-upload.component';
import { ParishListComponent } from './navigation-components/parish/parish-list/parish-list.component';
import { ParishDetailComponent } from './navigation-components/parish/parish-detail/parish-detail.component';
import { NewsListComponent } from './navigation-components/news/news-list/news-list.component';
import { NewsDetailComponent } from './navigation-components/news/news-detail/news-detail.component';

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  {path: 'incidents/list', component: IncidentListComponent, canActivate:[AuthGuard]},
  {path: 'incident', component: IncidentDetailComponent, canActivate:[AuthGuard]},
  {path: 'incident/:id', component: IncidentDetailComponent, canActivate:[AuthGuard]},
  {path: 'vehicles/list', component: VehicleListComponent, canActivate:[AuthGuard]},
  {path: 'vehicle', component: VehicleDetailComponent, canActivate:[AuthGuard]},
  {path: 'vehicle/:id', component: VehicleDetailComponent, canActivate:[AuthGuard]},
  {path: 'trailers/list', component: TrailerListComponent, canActivate: [AuthGuard]},
  {path: 'trailer/:id', component: TrailerDetailComponent, canActivate: [AuthGuard]},
  {path: 'trailer', component: TrailerDetailComponent, canActivate: [AuthGuard]},
  {path: 'routetemplates/list', component: RouteTemplateListComponent, canActivate: [AuthGuard]},
  {path: 'routetemplate/:id', component: RouteTemplateEditComponent, canActivate: [AuthGuard]},
  {path: 'routetemplate', component: RouteTemplateDetailComponent, canActivate: [AuthGuard]},
  {path: 'locations/list', component: LocationListComponent, canActivate: [AuthGuard]},
  {path: 'location/:id', component: LocationDetailComponent, canActivate: [AuthGuard]},
  {path: 'location', component: LocationDetailComponent, canActivate: [AuthGuard]},
  {path: 'containers/upload', component: ContainerUploadComponent, canActivate: [AuthGuard]},
  {path: 'containers/list', component: ContainerListComponent, canActivate: [AuthGuard]},
  {path: 'container/:id', component: ContainerDetailComponent, canActivate: [AuthGuard]},
  {path: 'container', component: ContainerDetailComponent, canActivate: [AuthGuard]},
  {path: 'collectionmessages/list', component: MessageListComponent, canActivate: [AuthGuard]},
  {path: 'collectionmessage/:id', component: MessageDetailComponent, canActivate: [AuthGuard]},
  {path: 'collectionmessage', component: MessageDetailComponent, canActivate: [AuthGuard]},
  {path: 'containertypes/list', component: ContainerTypeListComponent, canActivate: [AuthGuard]},
  {path: 'containertype/:id', component: ContainerTypeDetailComponent, canActivate: [AuthGuard]},
  {path: 'containertype', component: ContainerTypeDetailComponent, canActivate: [AuthGuard]},
  {path: 'localities/list', component: LocalityListComponent, canActivate: [AuthGuard]},
  {path: 'locality/:id', component: LocalityDetailComponent, canActivate: [AuthGuard]},
  {path: 'locality', component: LocalityDetailComponent, canActivate: [AuthGuard]},
  {path: 'freguesias/list', component: ParishListComponent, canActivate: [AuthGuard]},
  {path: 'freguesia/:id', component: ParishDetailComponent, canActivate: [AuthGuard]},
  {path: 'freguesia', component: ParishDetailComponent, canActivate: [AuthGuard]},
  {path: 'codegenerators/list', component: CodeGeneratorListComponent, canActivate: [AuthGuard]},
  {path: 'codegenerator/:id', component: CodeGeneratorDetailComponent, canActivate: [AuthGuard]},
  {path: 'codegenerator', component: CodeGeneratorDetailComponent, canActivate: [AuthGuard]},
  {path: 'routes/list', component: RouteListComponent, canActivate:[AuthGuard]},
  {path: 'users/list', component: GiorListComponent, canActivate:[AuthGuard]},
  {path: 'user/:id', component: UserDetailComponent, canActivate:[AuthGuard]},
  {path: 'news/list', component: NewsListComponent, canActivate:[AuthGuard]},
  {path: 'article/:id', component: NewsDetailComponent, canActivate:[AuthGuard]},
  {path: 'article', component: NewsDetailComponent, canActivate:[AuthGuard]},
  {path: 'route/:id', component: RouteCompletedDetailComponent, canActivate:[AuthGuard]},
  {path: 'user', component: UsersCreateComponent, canActivate:[AuthGuard]},
  {path: 'route-builder', component: RouteBuilderComponent, canActivate:[AuthGuard]},
  {path: 'statistics/route', component: RoutestatsComponent, canActivate:[AuthGuard]},
  {path: 'statistics/reports', component: StatisticsReportsComponent, canActivate:[AuthGuard]},
  {path: 'welcome', component: WelcomeComponent, canActivate:[AuthGuard]},
  {path: 'unauthorized', component:PageNotFoundComponent},
  //{path: '#', component: TestDevComponent, canActivate: [AuthGuard]},
  {path: '**', component: PageNotFoundComponent},
];


@NgModule({
  imports: [
   RouterModule.forRoot(
    routes, {
    onSameUrlNavigation: 'reload'
    //, enableTracing: true// <-- debugging purposes only
    ,
    relativeLinkResolution: 'legacy'
})
   ],
   exports: [
     RouterModule
   ]
})
export class AppRoutingModule {}
