import { Observable } from 'rxjs';
import { AbstractScreenHandler, CRUDGenericService, TypeValidatorService } from 'attcei-bo-fw';
import { ScreenHandler } from 'attcei-bo-fw/lib/screen/interfaces/ScreenHandler';
import { Injectable } from '@angular/core';
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { Sort } from '@angular/material/sort';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';
import { BasicNewsDTO } from '../classes/dto/BasicNewsDTO';
import { NewsDTO } from '../classes/dto/NewsDTO';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn:'root'
})
export class NewsScreenHandler extends AbstractScreenHandler<BasicNewsDTO, NewsDTO> implements ScreenHandler<BasicNewsDTO, NewsDTO>{
    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }
    
    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<NewsDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }

    post(item: BasicNewsDTO): Observable<NewsDTO> {
        console.log('Recebi no post -> ', item);
        var dto: BasicNewsDTO = super.parse(item);
        var res: Observable<NewsDTO> = this.service.postData(this.getURL(),dto,true);
        console.log('result -> ', res);
        return res;
    }

    update(item: NewsDTO):Observable<NewsDTO> {
        console.log('Recebi no update -> ', item);
        var dto: NewsDTO = super.parse(item);
        var res: Observable<NewsDTO> = this.service.putData(this.getURL(), dto.id, dto, true);
        console.log('parsed -> ', res);
        return res;
    }

    delete(item: any):Observable<NewsDTO> {
        return this.service.delete(this.getURL(), item.id, true);
    }

    getItem(id: number):Observable<NewsDTO> {
        return this.service.getDataItem(this.getURL(), true, id).pipe(map((item: any) => {
            item.publishedAt = this.toDate(item.publishedAt);
            item.publishedUntil = this.toDate(item.publishedUntil);
            return this.parse(item);
        }));
    }

    toDate(obj: any): Date {
        if (obj == null) {
          return null;
        } else if (obj instanceof Date) {
          return obj;
        } else if (typeof obj === 'string') {
          return new Date(obj);
        } else if (Array.isArray(obj)) {
          return new Date(obj[0],obj[1] - 1, obj[2]);
        }
        //is JavaDateTime
        return new Date(obj.date.year, obj.date.month - 1, obj.date.day, obj.time.hour, obj.time.minute, obj.time.second);
    }

    getURL(): string {
        return 'news';
    }

    getId(item: NewsDTO): number {
        return item.id;
    }

    protected getUpdateTypeName(): string {
        return 'NewsDTO';
    }

    protected getCreateTypeName(): string {
        return 'BasicNewsDTO';
    }
}