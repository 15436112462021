import { Injectable } from '@angular/core';

import { CRUDGenericService } from 'attcei-bo-fw';
import { Observable } from 'rxjs';
import { PaginatedDTO } from './dto/PaginatedDTO';
import { RouteVehicleDTO } from './dto/RouteVehicleDTO';
import { map, flatMap, filter } from 'rxjs/operators';
import { VehicleDTO } from 'src/app/classes/dto/VehicleDTO';
import { TrailerDTO } from 'src/app/classes/dto/TrailerDTO';

@Injectable()
export class VehiclesService {

    constructor(private service: CRUDGenericService) { }

    get(): Observable<RouteVehicleDTO[]> {
        return this.service.getDataItem('vehicle', true).pipe(
            map((x: PaginatedDTO<VehicleDTO>) => x.contents),
            flatMap(vehicles => {
                const trailers = vehicles.map(v => v.trailerId)
                    .filter((elem, index, self) => {
                        return index === self.indexOf(elem);
                    });

                return this.service
                    .getDataItem('trailer?filter={"id":' + JSON.stringify(trailers) + '}', true)
                    .pipe(
                        map((x: PaginatedDTO<TrailerDTO>) => x.contents),
                        map((t: TrailerDTO[]) => vehicles.map(vehicle => new RouteVehicleDTO(
                            vehicle.id,
                            vehicle.licencePlate,
                            this.findCapacity(vehicle, t)))
                            .filter(v => !!v.capacity)));
            }));
    }

    private findCapacity(v: VehicleDTO, trailers: TrailerDTO[]): number {
        return trailers.filter(t => t.id === v.trailerId).map(t => t.capacity).shift();
    }

}
