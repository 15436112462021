import { Component, OnInit, Input } from '@angular/core';
import { CodeGeneratorScreenHandler } from 'src/app/handlers/CodeGeneratorScreenHandler';
import screen from '../../../../assets/CodeGeneratorScreen.json';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-codegenerator-detail',
  templateUrl: './codegenerator-detail.component.html',
  styleUrls: ['./codegenerator-detail.component.scss']
})
export class CodeGeneratorDetailComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  @Input() uid: string;

  constructor(private route: ActivatedRoute, public screenHandler: CodeGeneratorScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');
  }


}
