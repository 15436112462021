import { Component, OnInit } from '@angular/core';
import { CRUDGenericService } from 'attcei-bo-fw';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormControl, FormGroupDirective, NgForm, FormBuilder } from '@angular/forms';
import { BuilderDialogComponent } from '../../route/route-builder/builder-dialog/builder-dialog.component';
import { MeasurementScreenHandler } from 'src/app/handlers/MeasurementScreenHandler';
import measurementScreen from '../../../../assets/MeasurementScreen.json';
import incidentScreen from '../../../../assets/UserIncidentScreen.json';
import { IncidentScreenHandler } from 'attcei-bo-fw';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}


@Component({
  selector: 'gior-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  public eventFilter: any;

  public measurementFilter: any;

  public incidentFilter: any;

  public measurementScreenStructure = JSON.parse(JSON.stringify(measurementScreen))

  public incidentScreenStructure = JSON.parse(JSON.stringify(incidentScreen))

  private id: number;

  public user: any = {};

  public photo = '';

  public loading = false;

  //public passwordError = new PasswordErrorStateMatcher();


  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private service: CRUDGenericService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    public measurementScreenHandler: MeasurementScreenHandler,
    public incidentScreenHandler: IncidentScreenHandler) { }

  ngOnInit() {
  
    this.id = Number.parseInt(this.route.snapshot.paramMap.get('id'));

    this.eventFilter = {createdBy: this.id};
    this.measurementFilter = {createdBy: this.id, isManual: true};
    this.incidentFilter = {createdBy: this.id};
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.service.getDataItem('user/' + this.id + '/wprofile', true)
      .subscribe(u => {
        this.loading = false;
        this.photo = u.profile.photo;
        this.user = {
          name: u.name,
          email: u.profile.email,
          role: u.role,
          friendlyName: u.profile.friendlyName,
          vatNumber: u.profile.vatNumber,
        };
      }, error => {
        this.user = {};
        this.loading = false;
        const snack = this.snackBar.open('Ocorreu um erro ao carregar os dados', '', { duration: 2000 });
      });
  }

  submitProfile() {

    const toSave = this.user;
    Object.keys(toSave).forEach((key) => (toSave[key] == null) && delete toSave[key]); // remove empty values

    this.openGenericDialog(
      'Submissão de novos dados',
      'Tem a certeza que pretende alterar os dados de utilizador?',
      'Cancelar', 'Confirmar')
      .subscribe(result => {
        if (result === 'Confirmar') {
          this.loading = true;
          this.service.postData('user/' + this.id + '/profile', toSave, true).subscribe(r => {
            this.loadData();
          }, error => {
            this.loading = false;
            this.snackBar.open('Ocorreu um erro a guardar os dados, por favor tente mais tarde', '', { duration: 2000 });
          });
        }
      });
  }

  isValid(): boolean {
    return true;
  }

  openGenericDialog(title: string, message: string, ...btns: string[]): Observable<any> {
    const config = new MatDialogConfig<any>();
    config.data = {title, message, btns};
    const dialogRef = this.dialog.open(BuilderDialogComponent, config);
    return dialogRef.afterClosed();
  }

}
