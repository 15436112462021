import { Component, OnInit } from '@angular/core';
import { LocalityScreenHandler } from 'src/app/handlers/LocalityScreenHandler';
import screen from '../../../../assets/LocalityScreen.json';


@Component({
  selector: 'app-locality-list',
  templateUrl: './locality-list.component.html',
  styleUrls: ['./locality-list.component.scss']
})
export class LocalityListComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  constructor(public screenHandler: LocalityScreenHandler) { }

  ngOnInit() {
  }

}