import { Component, OnInit, Input } from '@angular/core';
import { LocalityScreenHandler } from 'src/app/handlers/LocalityScreenHandler';
import screen from '../../../../assets/LocalityScreen.json';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-locality-detail',
  templateUrl: './locality-detail.component.html',
  styleUrls: ['./locality-detail.component.scss']
})
export class LocalityDetailComponent implements OnInit {

  public screenStructure = JSON.parse(JSON.stringify(screen));

  @Input() uid: string;

  constructor(private route: ActivatedRoute, public screenHandler: LocalityScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');
  }


}
