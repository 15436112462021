import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { LocationDTO } from '../route-template-edit/LocationDTO';
import { ExcelService } from 'attcei-bo-fw';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-route-template-edit-table',
  templateUrl: './route-template-edit-table.component.html',
  styleUrls: ['./route-template-edit-table.component.scss']
})
export class RouteTemplateEditTableComponent implements OnInit, OnChanges {

  @Input() private templateId: number;

  @Input() locations = new Observable<LocationDTO[]>();

  @Input() inRoute: number[];
  
  @Output() inRouteChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output() doSave: EventEmitter<any> = new EventEmitter<any>();

  inRouteSubject = new Subject<number[]>();

  inRouteLocations = new BehaviorSubject<LocationDTO[]>([]);

  constructor(public excel: ExcelService) { }


  save() {
    this.doSave.emit({});
  }

  export() {
    const data:any[] = [['ID', 'Código', 'Morada', 'Localidade', 'Latitude', 'Longitude']];
    this.inRouteLocations.value
      .map(l => [l.id, l.code, l.address, l.locality, l.latitude, l.longitude])
      .forEach(a => data.push(a));
    this.excel.exportArrayAsExcelFile(data, 'circuito.xlsx');
  }

  
  removeFromRoute(e) {
    this.inRoute = this.inRoute.filter(r => r != e.id);
    this.inRouteSubject.next(this.inRoute);
    this.inRouteChange.emit(this.inRoute);
  }

  addToRoute(e) {
    if (!this.inRoute.includes(e.id)) {
      this.inRoute.push(e.id);
      this.inRouteSubject.next(this.inRoute);
      this.inRouteChange.emit(Object.assign([], this.inRoute));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inRouteSubject.next(this.inRoute);
  }


  ngOnInit() {
    combineLatest([this.locations, this.inRouteSubject])
      .pipe(map(arr => arr[0].filter(lo => arr[1].includes(lo.id))))
      .subscribe(this.inRouteLocations);
  }

}
