import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContainerTypeScreenHandler } from 'src/app/handlers/ContainerTypeScreenHandler';
import screen from '../../../../assets/ContainerTypeScreen.json'

@Component({
  selector: 'app-container-type-detail',
  templateUrl: './container-type-detail.component.html',
  styleUrls: ['./container-type-detail.component.scss']
})
export class ContainerTypeDetailComponent implements OnInit {

  public eventFilter: any;

  public screenStructure = JSON.parse(JSON.stringify(screen))

  @Input() uid: string;

  constructor(private route:ActivatedRoute, public screenHandler: ContainerTypeScreenHandler) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');

    this.eventFilter = {container: Number.parseInt(this.uid)};
  }


}
