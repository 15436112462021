import { Observable } from "rxjs";
import { AbstractScreenHandler, CRUDGenericService, TypeValidatorService } from 'attcei-bo-fw';
import { ScreenHandler } from 'attcei-bo-fw/lib/screen/interfaces/ScreenHandler';
import { Injectable } from '@angular/core';
import { PaginationState } from 'attcei-bo-fw/lib/classes/PaginationState';
import { Sort } from '@angular/material/sort';
import { PaginatedDTO } from 'attcei-bo-fw/lib/classes/dto/PaginatedDTO';
import { BasicContainerTypeDTO } from '../classes/dto/BasicContainerTypeDTO';
import { ContainerTypeDTO } from '../classes/dto/ContainerTypeDTO';

@Injectable({
    providedIn:'root'
})
export class ContainerTypeScreenHandler extends AbstractScreenHandler<BasicContainerTypeDTO, ContainerTypeDTO> implements ScreenHandler<BasicContainerTypeDTO, ContainerTypeDTO>{
    constructor(private service: CRUDGenericService, typeValidator: TypeValidatorService){
        super(typeValidator);
    }
    
    loadListData(filter: any, paginationState: PaginationState, sort: Sort): Observable<PaginatedDTO<ContainerTypeDTO>> {
        var url:string = this.getURL() + this.buildInvocationWithPaginationAndFilter(paginationState, filter, sort);
        return this.service.getDataList(url, true);
    }

    post(item: BasicContainerTypeDTO):Observable<ContainerTypeDTO> {
        console.log("Recebi no post -> ", item);
        var dto: BasicContainerTypeDTO = super.parse(item);
        var res: Observable<ContainerTypeDTO> = this.service.postData(this.getURL(),dto,true);
        console.log("result -> ", res);
        return res;
    }

    update(item: ContainerTypeDTO):Observable<ContainerTypeDTO> {
        console.log("Recebi no update -> ", item);
        var dto: ContainerTypeDTO = super.parse(item);
        var res: Observable<ContainerTypeDTO> = this.service.putData(this.getURL(), dto.id, dto, true);
        console.log("parsed -> ", res);
        return res;
    }

    delete(item: any):Observable<ContainerTypeDTO> {
        return this.service.delete(this.getURL(), item.id, true);
    }

    getItem(id: number):Observable<ContainerTypeDTO> {
        return this.service.getDataItem(this.getURL(), true, id);
    }

    getURL(): string {
        return "containertype"
    }

    getId(item: ContainerTypeDTO): number {
        return item.id;
    }
    
    protected getUpdateTypeName(): string {
        return "ContainerTypeDTO";
   
    }    
    
    protected getCreateTypeName(): string {
        return "BasicContainerTypeDTO";
    }
}